import rest from '../utils/rest';
import { beforeRequest, beforeResponse, createNotification } from '../utils/helpers';

import { GET_ERRORS, GET_REVIEW_LIST, GET_REVIEW_DETAIL, UPDATE_REVIEW, DELETE_REVIEW, CREATE_REVIEW } from './types';

// Get List of Reviews
export const getReviewLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/review/v1/auth/admin/reviews?${params}`)
      .then((res) => {
        dispatch({
          type: GET_REVIEW_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteReview = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/review/v1/auth/admin/reviews/${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_REVIEW,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createReview = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/review/v1/auth/admin/reviews`, values)
      .then((res) => {
        dispatch({
          type: CREATE_REVIEW,
        });
        resolve(res);
        createNotification('success', 'Success');
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        createNotification('error', "Something's wrong");
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateReview = (id, values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/review/v1/auth/admin/reviews/${id}`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_REVIEW,
        });
        createNotification('success', 'Success');
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        createNotification('error', "Something's wrong");
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const approveReview = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/review/v1/auth/admin/reviews/${id}/enable`, {})
      .then((res) => {
        dispatch({
          type: UPDATE_REVIEW,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const unapproveReview = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/review/v1/auth/admin/reviews/${id}/disable`, {})
      .then((res) => {
        dispatch({
          type: UPDATE_REVIEW,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getReviewDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/review/v1/auth/admin/reviews/${id}`)
      .then((res) => {
        dispatch({
          type: GET_REVIEW_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
