import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';

import {
  createMembership,
  updateMembership,
  getMembershipDetail,
  getMembershipLists,
} from '../../../actions/membership';
import { withRouter } from '../../../withRouter';

const requiredFields = ['rank', 'total_revenue', 'fee_charge_seller', 'number_of_on_hold_days'];

class FormSellerRanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Membership' : 'Create Membership',
      membership: {},
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    this.setState({
      membership: {
        ...this.state.membership,
        [name]: value,
      },
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.membership, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      const values = this.state.membership;
      values.fee_charge_seller = values.fee_charge_seller * 1;

      if (this.props.router.params.id) {
        this.props.updateMembership(values);
      } else {
        this.props.createMembership(values);
      }
      this.props.getMembershipLists();
      createNotification('success', lang('success.update'));
    }
  }

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getMembershipDetail(this.props.router.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.membershipDetail !== this.props.membershipDetail) {
      this.setState({ membership: nextProps.membershipDetail.data });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <form class="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputRank">
              Name
            </label>
            <input
              class="form-control"
              id="inputRank"
              type="text"
              required="required"
              value={this.state.membership?.rank}
              onChange={(e) => this.updateState('rank', e.target.value)}
            />
            <div class="invalid-feedback">Please enter rank name</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Milestone
            </label>
            <input
              class="form-control"
              id="inputEmail"
              type="text"
              required="required"
              value={this.state.membership?.total_revenue}
              onChange={(e) => this.updateState('total_revenue', e.target.value * 1)}
            />
            <div class="invalid-feedback">Please enter Milestone</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              On Hold Days
            </label>
            <input
              class="form-control"
              id="inputEmail"
              type="text"
              required="required"
              value={this.state.membership?.number_of_on_hold_days}
              onChange={(e) => this.updateState('number_of_on_hold_days', e.target.value)}
            />
            <div class="invalid-feedback">Please enter On Hold Days</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Transaction Fee
            </label>
            <input
              class="form-control"
              id="inputEmail"
              type="text"
              required="required"
              value={this.state.membership?.fee_charge_seller}
              onChange={(e) => this.updateState('fee_charge_seller', e.target.value)}
            />
            <div class="invalid-feedback">Please enter transaction fee</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputAmount">
              Order Amount
            </label>
            <input
              class="form-control"
              id="inputAmount"
              type="text"
              value={this.state.membership?.order_amount}
              onChange={(e) => this.updateState('order_amount', e.target.value)}
            />
          </div>
          <div class="col-12">
            <button class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormSellerRanks.propTypes = {
  auth: PropTypes.object.isRequired,
  membershipDetail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createMembership: PropTypes.func.isRequired,
  updateMembership: PropTypes.func.isRequired,
  getMembershipDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  membershipDetail: state.memberships.membership,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createMembership,
  updateMembership,
  getMembershipDetail,
  getMembershipLists,
})(withRouter(FormSellerRanks));
