import { Centrifuge } from 'centrifuge';
import moment from 'moment';
import { sendNotification } from './notificationHelper';
import { escapeHTML, pasteIntoInput } from './helpers';
import config from '../config';

import { TRANSACTION_STATUS_BUYER_NOT_RECEIVE } from '../utils/constants';

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const cleanup = () => {
  if (typeof window !== 'undefined' && window.sub) {
    window.sub.unsubscribe();
    window.sub.removeAllListeners();
    window.centrifuge.disconnect();
  }
};

const sendImage = (img_urls) => {
  if (img_urls) {
    window.sub.publish({
      input: JSON.stringify({
        action: 'image',
        image: img_urls,
      }),
    });
  }
};

const handleChat = (buyer, seller, userData, roomName, roomType = 'sell', actor = 'seller') => {
  cleanup();
  const chatThreadWrapper = document.getElementsByClassName('chat-thread').item(0);
  const chatThread = document.querySelector('#chat-thread');
  const messageInput = document.querySelector('#chat-message-input');
  const messageInputSend = document.querySelector('#chat-message-send');
  window.otherMember = buyer;

  chatThread.innerHTML = '';

  const centrifuge = new Centrifuge('wss://api.playswap.gg/socket/connection/websocket', {
    data: buyer.id + ':' + seller.id + ':' + roomName + ':' + roomType + ':' + actor,
  });
  let member = [];

  centrifuge
    .on('connecting', function (ctx) {
      console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
    })
    .on('connected', function (ctx) {
      console.log(`connected`);
      member = Object.values(ctx.data);
      console.log('in connected', member);
    })
    .on('disconnected', function (ctx) {
      console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
    })
    .connect();

  const sub = centrifuge.newSubscription('rooms:' + roomName);
  window.sub = sub;
  sub.subscribe();
  centrifuge.connect();
  window.centrifuge = centrifuge;
  let timestampMgs = 0;
  let prevTimestampMgs = 0;

  window.sub.on('subscribed', function (ctx) {
    if (ctx.data.length !== 0) {
      let i = ctx.data.length - 1;
      timestampMgs = ctx.data[i][1];
      while (i >= 0) {
        const msg = generateMessage(
          ctx.data[i][0],
          userData.id == ctx.data[i][2] ? 'justify-content-end' : '',
          seller.id == ctx.data[i][2] ? seller : buyer,
          ctx.data[i][1],
          JSON.stringify({
            roomId: 'rooms:' + roomName,
            id: ctx.data[i][3],
            sender: ctx.data[i][2],
            msg: JSON.stringify(ctx.data[i][0]),
            timezone: ctx.data[i][4],
          }),
        );
        if (msg) chatThread.appendChild(msg);

        setTimeout(() => {
          chatThreadWrapper.scrollTop = chatThreadWrapper.scrollHeight;
        }, 500);
        i--;
      }
    }
  });

  window.sub
    .on('publication', function (ctx) {
      const chatNewThread = document.createElement('div');
      chatNewThread.className = seller.id == ctx.data.user ? 'justify-content-end' : '';

      if ('history' in ctx.data) {
        if (timestampMgs > 0) {
          let i = 0; // minus 1 because index start at 0
          prevTimestampMgs = timestampMgs;
          timestampMgs = ctx.data['history'][ctx.data['history'].length - 1]
            ? ctx.data['history'][ctx.data['history'].length - 1][1]
            : 0;

          while (i <= ctx.data['history'].length - 1) {
            if (prevTimestampMgs > ctx.data['history'][i][1]) {
              const msg = generateMessage(
                ctx.data['history'][i][0],
                userData.id == ctx.data['history'][i][2] ? 'justify-content-end' : '',
                seller.id == ctx.data['history'][i][2] ? seller : buyer,
                ctx.data['history'][i][1],
                JSON.stringify({
                  roomId: 'rooms:' + roomName,
                  id: ctx.data['history'][i][3],
                  sender: ctx.data['history'][i][2],
                  msg: JSON.stringify(ctx.data['history'][i][0]),
                  timezone: ctx.data['history'][i][4],
                }),
              );
              if (msg) chatThread.insertBefore(msg, chatThread.firstChild);
            }
            i++;
          }
        }
      }
      if (ctx.data['user'] === 'system') {
        const msg = generateMessage(ctx.data.input, '', userData, '');
        if (msg) chatThread.appendChild(msg);
        chatThreadWrapper.scrollTop = chatThreadWrapper.scrollHeight;
      } else if (!('history' in ctx.data)) {
        const msg = generateMessage(
          ctx.data.input,
          userData.id == ctx.data.user ? 'justify-content-end' : '',
          seller.id == ctx.data.user ? seller : buyer,
          '',
          JSON.stringify({
            roomId: 'rooms:' + roomName,
            id: ctx.data.id,
            sender: ctx.data.user,
            msg: JSON.stringify(ctx.data.input),
            timezone: ctx.data.timezone,
          }),
        );
        if (msg) chatThread.appendChild(msg);
        setTimeout(() => {
          chatThreadWrapper.scrollTop = chatThreadWrapper.scrollHeight;
        }, 500);
      }
    })
    .subscribe();

  chatThreadWrapper.addEventListener('scroll', function (e) {
    if (chatThreadWrapper.scrollTop === 0) {
      // get history
      if (timestampMgs !== 0 && timestampMgs !== prevTimestampMgs) {
        prevTimestampMgs = timestampMgs;
        let message = 'history:' + timestampMgs;
        sub.publish({ input: message }).then(
          function () {
            console.log('publish history ok: ', message);
          },
          function (err) {
            console.log('publish history error:', err);
          },
        );
      }
    } else if (chatThreadWrapper.scrollTop + chatThreadWrapper.clientHeight === chatThreadWrapper.scrollHeight) {
      console.log('scroll botom');
    }
  });
  if (messageInput) {
    messageInput.focus();
    messageInput.onkeyup = function (e) {
      if (e.keyCode == 13 && (e.shiftKey || e.altKey)) {
        if (e.type == 'keypress') {
          pasteIntoInput(this, '\n');
        }
      } else if (e.keyCode === 13) {
        const message = messageInput.value;
        const validateMessage = (message || '').replaceAll('\n', '');

        if (!validateMessage) {
          return;
        } else {
          const postMessage = {
            text: message,
          };
          sub.publish({ input: JSON.stringify(postMessage) }).then(
            function () {
              // success ack from Centrifugo received
              console.log('publish ok: ', postMessage);
              if (window.otherMember) sendNotification(window.otherMember.user_id);
            },
            function (err) {
              // publish call failed with error
              console.log('publish error:', err);
            },
          );

          messageInput.value = '';
        }
      }
    };
    messageInputSend.addEventListener('click', function (e) {
      const message = messageInput.value;
      if (!message) {
        return;
      }
      const postMessage = {
        text: message,
      };
      window.sub.publish({ input: JSON.stringify(postMessage) }).then(
        function () {
          // success ack from Centrifugo received
          console.log('publish ok: ', postMessage);
          if (window.otherMember) sendNotification(window.otherMember.user_id);
        },
        function (err) {
          // publish call failed with error
          console.log('publish error:', err);
        },
      );

      messageInput.value = '';
    });
  }
};

const generateMessage = (message, className, userData, time, messageObj) => {
  if (isJson(message)) {
    const item = JSON.parse(message);
    let msgTime = time ? new Date(time * 1000) : new Date();
    const timeDisplay =
      moment().diff(moment(msgTime), 'days') >= 1 ? moment(msgTime).format('lll') : moment(msgTime).fromNow();
    if (!item.order) {
      let chatNewThread = document.createElement('div');
      chatNewThread.className = 'd-flex chat-message';
      let messageHtml = '';
      if (item.action == 'image') {
        messageHtml += `<img onclick="imgModal(this.src)" onload="scrollbottom()" style="max-width: 200px;" alt="image" src="${item.image}" />`;
      } else if (item.text) {
        messageHtml += `<p class="mb-0">${escapeHTML(item.text)}</p>`;
      }
      const actionsHTML = `<div class="d-none d-sm-flex">
                            <div class="hover-actions show position-relative align-self-center me-2">
                              <button class="btn p-2 fs-10" onclick="deleteMessage(this,${messageObj.replaceAll(
                                '"',
                                "'",
                              )})"><span class="fa-solid fa-trash"></span></button>
                            </div>
                          </div>`;
      let html = `<div class="d-flex mb-2 ${className ? className : ''} flex-1">
          <div class="w-100 w-xxl-75">

            <div class="d-flex ${className ? 'flex-end-center' : ''}">
              ${
                className
                  ? ''
                  : `<div class="avatar avatar-m me-3 flex-shrink-0">
                    <img class="rounded-circle" src="${
                      userData?.user_avatar ||
                      'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                    }" alt="" />
                  </div>`
              }
              <div class="d-flex flex-1 flex-column ${className ? ' align-items-end' : 'align-items-start'}">
                <p class="mb-1 fs-10 text-body-tertiary text-opacity-85 fw-semibold ${className ? 'text-end' : ''}">${
        userData.user_name || userData.name || userData.full_name
      }${className ? ' (Seller) ' : ' (Buyer)'}</p>

              <div class="chat-message-content received me-2">
                <div class="mb-1 ${
                  className ? 'sent-message-content text-white bg-primary' : 'received-message-content'
                } border rounded-2 p-3">
                  ${messageHtml.replaceAll('\n', '<br/>')}
                </div>
              </div>
              ${className ? actionsHTML : ''}
              <p class="mb-0 fs-10 text-body-tertiary text-opacity-85 fw-semibold ${
                className ? 'text-end' : ''
              }">${timeDisplay}</p>
              </div>
              ${
                className
                  ? `<div class="avatar avatar-m me-3 flex-shrink-0">
                  <img class="rounded-circle" src="${
                    userData?.user_avatar || 'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                  }" alt="" />
                </div>`
                  : ''
              }
            </div>

          </div>
        </div>`;

      chatNewThread.innerHTML = html;
      return chatNewThread;
    }
    return '';
  } else {
    return '';
  }
};

const confirmReceiveFail = async (id, order_status, order) => {
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
      'access-token': sessionStorage.getItem('key_jwt'),
    },
    method: 'POST',
    body: JSON.stringify({
      tx_id: id,
      status: order_status,
    }),
  };
  const requestUrl = `${config.url}/payment/v1/auth/buyer/confirm`;
  const response = await fetch(requestUrl, mergedOptions);
  const data = await response.json();
  if (order_status == 'fail') {
    sendNotification(
      order.buyer_id,
      JSON.stringify({
        ...order,
        status: TRANSACTION_STATUS_BUYER_NOT_RECEIVE,
      }),
    );
    sendNotification(
      order.seller_id,
      JSON.stringify({
        ...order,
        status: TRANSACTION_STATUS_BUYER_NOT_RECEIVE,
      }),
    );
  }
};

export { handleChat, cleanup, isJson, sendImage, confirmReceiveFail };
