import { Centrifuge } from 'centrifuge';
import config from '../config';

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const sendNotification = async (user_id, msg) => {
  if (sessionStorage.getItem(config.tokenKey)) {
    const mergedOptions = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': sessionStorage.getItem(config.tokenKey),
      },
      method: 'POST',
      body: JSON.stringify({
        room: `message:${user_id}`,
        msg: msg || 'New Message',
      }),
    };
    const requestUrl = `${config.url}/chat/be/publish-noti`;
    const response = await fetch(requestUrl, mergedOptions);
    await response.json();
  }
};

const unsubscribeNotification = async () => {
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
      'access-token': sessionStorage.getItem(config.tokenKey),
    },
    method: 'POST',
    body: JSON.stringify({}),
  };
  const requestUrl = `${config.url}/chat/be/unsubscribe/`;
  const response = await fetch(requestUrl, mergedOptions);
  const data = await response.json();
  if (window.pushsub) {
    window.pushsub.unsubscribe();
    window.pushsub.removeAllListeners();
    window.pushcentrifuge.disconnect();
  }
};

const subscribeNotification = (user_id, callback = () => {}) => {
  const centrifuge = new Centrifuge('wss://api.playswap.gg/socket/connection/websocket', {
    data: 'message:' + user_id,
  });
  centrifuge
    .on('connecting', function (ctx) {
      console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
    })
    .on('connected', function (ctx) {
      console.log(`connected handleNotification`);
    })
    .on('disconnected', function (ctx) {
      console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
    })
    .connect();
  const sub = centrifuge.newSubscription('message:' + user_id);
  sub
    .on('publication', function (ctx) {
      callback(ctx.data);
    })
    .subscribe();
  window.pushcentrifuge = centrifuge;
  window.pushsub = sub;
};

export { isJson, sendNotification, subscribeNotification, unsubscribeNotification };
