import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import {
  GET_ERRORS,
  GET_SELLER_LIST,
  GET_SELLER_DETAIL,
  UPDATE_SELLER,
  NOTIFICATION_LIST,
  UPDATE_NOTIFICATION,
} from './types';

// Get List of Sellers
export const getSellerLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/account/v1/auth/admin/seller/list?${params}`)
      .then((res) => {
        dispatch({
          type: GET_SELLER_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateSeller = (id, data) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/account/v1/auth/admin/seller/${id}/update`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_SELLER,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const approveSeller = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/account/v1/auth/admin/seller/${id}/approved`, {})
      .then((res) => {
        dispatch({
          type: UPDATE_SELLER,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const unapproveSeller = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/account/v1/auth/admin/seller/${id}/unaprove`, {})
      .then((res) => {
        dispatch({
          type: UPDATE_SELLER,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getSellerDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/account/v1/auth/admin/seller/${id}`)
      .then((res) => {
        dispatch({
          type: GET_SELLER_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const notificationDataRead = () => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/chat/be/messages`)
      .then((res) => {
        dispatch({
          type: NOTIFICATION_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const notificationDataUpdate = (id, callback) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/chat/be/messages/${id}`)
      .then((res) => {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: res.data ? res.data : null,
        });
        callback();
        // resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const allNotificationDataUpdate = (callback) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/chat/be/messages/mark-all-read`)
      .then((res) => {
        dispatch({
          type: UPDATE_NOTIFICATION,
          payload: res.data ? res.data : null,
        });
        callback();
        // resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
