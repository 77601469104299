import _ from 'lodash';

import {
  GET_EXPENSE_LIST,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  GET_EXPENSE_DETAIL,
  GET_EXPENSE_CATEGORY_LIST,
  UPDATE_EXPENSE_CATEGORY,
  DELETE_EXPENSE_CATEGORY,
  GET_EXPENSE_CATEGORY_DETAIL,
} from '../actions/types';

const initialState = {
  expenses: {},
  expense_categories: {},
  expense: {},
  expense_category: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXPENSE_CATEGORY_LIST:
      return {
        ...state,
        expense_categories: action.payload,
      };
    case GET_EXPENSE_CATEGORY_DETAIL:
      return {
        ...state,
        expense_category: action.payload,
      };
    case GET_EXPENSE_LIST:
      return {
        ...state,
        expenses: action.payload,
      };
    case GET_EXPENSE_DETAIL:
      return {
        ...state,
        expense: action.payload,
      };
    case UPDATE_EXPENSE_CATEGORY:
    case DELETE_EXPENSE_CATEGORY:
    case UPDATE_EXPENSE:
    case DELETE_EXPENSE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
