import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';
import { confirmReceiveFail } from '../../../utils/chatHelper';
import lang from '../../../langs';
import { getTransactionsDetail } from '../../../actions/transaction';
import { createSupport } from '../../../actions/ticket';
import { withRouter } from '../../../withRouter';
import config from '../../../config';

const requiredFields = ['ticket_type', 'subject', 'description'];

class FormTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Create Ticket',
      currentOrder: {},
      attachment: null,
      ticket: {
        reason: '',
        email: '',
        subject: '',
        description: '',
        priority: 'Medium',
        status: 'Open',
        attachments: '',
        object_id: '',
        requested_with: '',
        requested_by: '',
        ticket_type: 'Other',
      },
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  updateState(name, value) {
    let params = {
      ...this.state.ticket,
      [name]: value,
    };
    if (name == 'object_id') {
      this.props.getTransactionsDetail(value);
    }
    if (name == 'ticket_type' && value == 'Other') {
      delete params['object_id'];
    }
    this.setState({
      ticket: params,
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.ticket, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      if (
        this.state.ticket.ticket_type == 'Dispute' &&
        (!this.state.ticket.object_id || this.state.ticket.object_id !== this.state.currentOrder?._id)
      ) {
        createNotification('error', 'Please enter Order ID');
        return false;
      }
      this.handleUploadIcon(() => this.processData());
    }
  }

  uploadImage = async (file, callback) => {
    const body = new FormData();
    body.append('file', file);
    body.append('path', `/users/`);
    body.append('type_upload', 'users');
    body.append('name', 'ticket');
    const me = this;

    fetch(`${config.url}/assets/v1/auth/admin/asset/upload`, {
      method: 'POST',
      body,
      headers: { 'access-token': sessionStorage.getItem(config.tokenKey) },
    })
      .then((response) => response.json())
      .then((json) => {
        callback(json?.data?.img_urls[0]);
      })
      .catch((err) => console.log(err));
  };

  handleImageUpload = (file, callback) => {
    var fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;
    const me = this;
    var extension = file.name.split('.').pop().toLowerCase();

    if (file && fileMb <= 10 && fileTypes.indexOf(extension) >= 0) {
      const reader = new FileReader();

      reader.onload = function (e) {
        me.uploadImage(file, callback);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  handleUploadIcon = (callback) => {
    const file = this.state.attachment;
    if (file) {
      this.handleImageUpload(file, (result) => {
        this.setState(
          {
            ticket: {
              ...this.state.ticket,
              attachments: result,
            },
          },
          () => callback(),
        );
      });
    } else {
      callback();
    }
  };

  processData = () => {
    this.props.createSupport(this.state.ticket);
    if (this.state.ticket.ticket_type == 'Dispute') {
      confirmReceiveFail(this.state.currentOrder?.tx_id, 'fail', this.state.currentOrder);
    }

    createNotification('success', lang('success.update'));
    this.props.getSupportLists({
      page: 1,
      ticket_type: this.state.ticket.ticket_type,
    });
    const link = this.state.ticket.ticket_type == 'Other' ? '/admin/support/tickets' : '/admin/support/disputes';
    this.props.router.navigate(link, { replace: true });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.transactions !== nextProps.transactions) {
      this.setState({
        currentOrder: nextProps.transactions?.transaction?.data,
      });
      this.setState({
        ticket: {
          ...this.state.ticket,
          requested_by: nextProps.transactions?.transaction?.data?.buyer_id,
          requested_with: nextProps.transactions?.transaction?.data?.seller_id,
        },
      });
    }
  }

  componentDidMount() {
    this.initImageUpload();
  }

  initImageUpload() {
    window.Dropzone.autoDiscover = false;
    const Selector = {
      DROPZONE: '#dropzone',
      DZ_ERROR_MESSAGE: '.dz-error-message',
      DZ_PREVIEW: '.dz-preview',
      DZ_PROGRESS: '.dz-preview .dz-preview-cover .dz-progress',
      DZ_PREVIEW_COVER: '.dz-preview .dz-preview-cover',
    };

    const ClassName = {
      DZ_FILE_PROCESSING: 'dz-file-processing',
      DZ_FILE_COMPLETE: 'dz-file-complete',
      DZ_COMPLETE: 'dz-complete',
      DZ_PROCESSING: 'dz-processing',
    };

    const Events = {
      ADDED_FILE: 'addedfile',
      REMOVED_FILE: 'removedfile',
      COMPLETE: 'complete',
    };

    const item = document.querySelector(Selector.DROPZONE);
    const that = this;
    const options = {
      url: '/assets/php/',
      addRemoveLinks: false,
      previewsContainer: item.querySelector(Selector.DZ_PREVIEW),
      previewTemplate: item.querySelector(Selector.DZ_PREVIEW).innerHTML,
      thumbnailWidth: null,
      thumbnailHeight: null,
      maxFilesize: 1,
      autoProcessQueue: false,
      filesizeBase: 1000,
      error(file, message) {
        if (file.previewElement) {
          file.previewElement.classList.add('dz-error');
          if (typeof message !== 'string' && message.error) {
            message = message.error;
          }
          for (let node of file.previewElement.querySelectorAll('[data-dz-errormessage]')) {
            node.textContent = message;
          }
        }
      },
    };
    item.querySelector(Selector.DZ_PREVIEW).innerHTML = '';

    const dropzone = new window.Dropzone(Selector.DROPZONE, options);

    dropzone.on(Events.ADDED_FILE, (e) => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_FILE_COMPLETE);
      }
      item.classList.add(ClassName.DZ_FILE_PROCESSING);
      that.setState({ attachment: e });
    });
    dropzone.on(Events.REMOVED_FILE, () => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_PROCESSING);
      }
      item.classList.add(ClassName.DZ_FILE_COMPLETE);
      that.setState({ attachment: null });
    });
    dropzone.on(Events.COMPLETE, () => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_PROCESSING);
      }

      item.classList.add(ClassName.DZ_FILE_COMPLETE);
    });
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <form class="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputTicketType">
              Ticket Type
            </label>
            <select
              class="form-control"
              id="inputTicketType"
              type="text"
              required="required"
              value={this.state.ticket?.ticket_type}
              onChange={(e) => this.updateState('ticket_type', e.target.value)}
            >
              <option>Select Type</option>
              {['Dispute', 'Other'].map((el) => (
                <option key={el} value={el}>
                  {el}
                </option>
              ))}
            </select>
            <div class="invalid-feedback">Please select ticket type</div>
          </div>
          {this.state?.ticket?.ticket_type == 'Dispute' && (
            <div class="col-md-12">
              <label class="form-label" htmlFor="inputOrderId">
                Order ID
              </label>
              <input
                class="form-control"
                id="inputOrderId"
                type="text"
                required="required"
                value={this.state.ticket?.object_id}
                onChange={(e) => this.updateState('object_id', e.target.value)}
              />
              <div class="invalid-feedback">Please enter Order ID</div>
            </div>
          )}
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputSubject">
              Reason
            </label>
            <input
              class="form-control"
              id="inputSubject"
              type="text"
              required="required"
              value={this.state.ticket?.subject}
              onChange={(e) => this.updateState('subject', e.target.value)}
            />
            <div class="invalid-feedback">Please enter Reason</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputDescription">
              Description
            </label>
            <textarea
              class="form-control"
              id="inputDescription"
              value={this.state.ticket?.description}
              onChange={(e) => this.updateState('description', e.target.value)}
            ></textarea>
          </div>
          <div class="mb-6">
            <h4 class="mb-3">Evidence</h4>
            {this.state?.ticket?.attachments && (
              <div class="mb-2">
                <img src={this.state?.ticket?.attachments} style={{ height: '50px' }} />
              </div>
            )}
            <div
              id="dropzone"
              class="dropzone dropzone-multiple p-0"
              data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'
            >
              <div class="fallback">
                <input type="file" name="file" />
              </div>
              <div class="dz-message text-center" data-dz-message="data-dz-message">
                <div class="dz-message-text">
                  <img class="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />
                  Drop your file here
                </div>
              </div>
              <div class="dz-preview dz-preview-multiple m-0 d-flex flex-column">
                <div class="d-flex pb-3 border-bottom border-translucent media px-2">
                  <div class="border p-2 rounded-2 me-2">
                    <img
                      class="rounded-2 dz-image"
                      src="/assets/img/icons/file.png"
                      alt="..."
                      data-dz-thumbnail="data-dz-thumbnail"
                    />
                  </div>
                  <div class="flex-1 d-flex flex-between-center">
                    <div>
                      <h6 data-dz-name="data-dz-name"></h6>
                      <div class="d-flex align-items-center">
                        <p class="mb-0 fs-9 text-body-quaternary lh-1" data-dz-size="data-dz-size"></p>
                        <div class="dz-progress">
                          <span class="dz-upload" data-dz-uploadprogress=""></span>
                        </div>
                      </div>
                      <span class="fs-10 text-danger" data-dz-errormessage="data-dz-errormessage"></span>
                    </div>
                    <div class="dropdown">
                      <button
                        class="btn btn-link text-body-quaternary btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="fas fa-ellipsis-h"></span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end border border-translucent py-2">
                        <a class="dropdown-item" href="#!" data-dz-remove="data-dz-remove">
                          Remove File
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <button class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormTickets.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
  transactions: state.transactions,
});

export default connect(mapStateToProps, {
  createSupport,
  getTransactionsDetail,
})(withRouter(FormTickets));
