import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import { GET_ERRORS, GET_SUBSCRIBER_LIST } from './types';

export const getSubscriberLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/email-management/v1/auth/admin/subscribers?${params}`)
      .then((res) => {
        dispatch({
          type: GET_SUBSCRIBER_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
