import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';

import { updateSeller, getSellerDetail } from '../../../actions/seller';
import { getMembershipLists } from '../../../actions/membership';
import { withRouter } from '../../../withRouter';

const requiredFields = ['full_name', 'email'];

class FormUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit User' : 'Create User',
      seller: {},
      membershipList: [],
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    this.setState({
      seller: {
        ...this.state.seller,
        [name]: value,
      },
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.seller, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      if (this.props.router.params.id) {
        let values = { ...this.state.seller };
        if (values['is_overrided'] == 1) {
          const membership = this.state.membershipList.filter((el) => el.rank == values['rank']);
          if (membership.length > 0) {
            values['fee_charge_seller'] = membership[0]['fee_charge_seller'];
            values['number_of_on_hold_days'] = membership[0]['number_of_on_hold_days'];
            values['order_amount'] = membership[0]['order_amount'];
          }
        } else {
          values['fee_charge_seller'] = this.props.sellerDetail.fee_charge_seller;
          values['number_of_on_hold_days'] = this.props.sellerDetail.number_of_on_hold_days;
          values['order_amount'] = this.props.sellerDetail.order_amount;
          values['rank'] = this.props.sellerDetail.rank;
          values['sold_products'] = this.props.sellerDetail.sold_products;
          values['total_rate'] = this.props.sellerDetail.total_rate;
          values['rating'] = this.props.sellerDetail.rating;
        }
        this.props.updateSeller(this.props.router.params.id, values);
      }
      createNotification('success', lang('success.update'));
    }
  }

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getSellerDetail(this.props.router.params.id);
      this.props.getMembershipLists();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sellerDetail !== this.props.sellerDetail) {
      this.setState({ seller: nextProps.sellerDetail });
    }
    if (nextProps.memberships !== this.props.memberships) {
      this.setState({
        ...this.state,
        membershipList: nextProps.memberships.memberships.data,
      });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <form class="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputFullName">
              Full Name
            </label>
            <input
              class="form-control"
              id="inputFullName"
              type="text"
              required="required"
              value={this.state.seller?.full_name}
              onChange={(e) => this.updateState('full_name', e.target.value)}
            />
            <div class="invalid-feedback">Please enter Full name</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Email
            </label>
            <input
              class="form-control"
              id="inputEmail"
              type="email"
              required="required"
              value={this.state.seller?.email}
              onChange={(e) => this.updateState('email', e.target.value)}
            />
            <div class="invalid-feedback">Please enter Email</div>
          </div>
          <div className="col-md-12">
            <input
              type="checkbox"
              className="custom-control-input"
              id="rememberMe"
              checked={this.state.seller?.is_overrided}
              onChange={(e) => this.updateState('is_overrided', e.target.checked ? 1 : 0)}
            />
            <label className="custom-control-label ms-1" htmlFor="rememberMe">
              Override Rank
            </label>
          </div>
          {this.state.seller?.is_overrided == 1 && (
            <>
              <div className="col-md-12">
                <label className="form-label" htmlFor="inputRank">
                  Seller Rank
                </label>
                <select
                  className="form-select form-select-sm"
                  onChange={(e) => this.updateState('rank', e.target.value)}
                >
                  <option selected="" value="">
                    Seller Rank
                  </option>
                  {(this.state.membershipList || []).map((el) => (
                    <option value={el.rank} selected={el.rank == this.state.seller?.rank}>
                      {el.rank.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12">
                <label className="form-label" htmlFor="inputFee">
                  Sold Products
                </label>
                <input
                  className="form-control"
                  id="inputFee"
                  type="text"
                  value={this.state.seller?.sold_products}
                  onChange={(e) => this.updateState('sold_products', e.target.value * 1)}
                />
              </div>
              <div className="col-md-12">
                <label className="form-label" htmlFor="inputDay">
                  Total Rating
                </label>
                <input
                  className="form-control"
                  id="inputDay"
                  type="text"
                  value={this.state.seller?.total_rate}
                  onChange={(e) => this.updateState('total_rate', e.target.value * 1)}
                />
              </div>
              <div className="col-md-12">
                <label className="form-label" htmlFor="inputRating">
                  Rating
                </label>
                <input
                  className="form-control"
                  id="inputRating"
                  type="text"
                  value={this.state.seller?.rating}
                  onChange={(e) => this.updateState('rating', e.target.value * 1)}
                />
              </div>
            </>
          )}
          <div class="col-12">
            <button class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormUsers.propTypes = {
  auth: PropTypes.object.isRequired,
  sellerDetail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateSeller: PropTypes.func.isRequired,
  getSellerDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  sellerDetail: state.sellers.seller,
  errors: state.errors,
  memberships: state.memberships,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  updateSeller,
  getSellerDetail,
  getMembershipLists,
})(withRouter(FormUsers));
