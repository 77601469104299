import rest from '../utils/rest';
import { beforeRequest, beforeResponse, createNotification } from '../utils/helpers';

import {
  GET_ERRORS,
  GET_EXPENSE_LIST,
  GET_EXPENSE_DETAIL,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  CREATE_EXPENSE,
  GET_EXPENSE_CATEGORY_LIST,
  GET_EXPENSE_CATEGORY_DETAIL,
  UPDATE_EXPENSE_CATEGORY,
  DELETE_EXPENSE_CATEGORY,
  CREATE_EXPENSE_CATEGORY,
} from './types';

// Get List of Expenses
export const getExpenseLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/expense?${params}`)
      .then((res) => {
        dispatch({
          type: GET_EXPENSE_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteExpense = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/payment/v1/auth/admin/expense/${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_EXPENSE,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createExpense = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/payment/v1/auth/admin/expense`, values)
      .then((res) => {
        dispatch({
          type: CREATE_EXPENSE,
        });
        resolve(res);
        createNotification('success', 'Success');
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        createNotification('error', "Something's wrong");
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateExpense = (id, values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/payment/v1/auth/admin/expense/${id}`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_EXPENSE,
        });
        createNotification('success', 'Success');
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        createNotification('error', "Something's wrong");
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getExpenseDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/payment/v1/auth/admin/expense/${id}`)
      .then((res) => {
        dispatch({
          type: GET_EXPENSE_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getExpenseCategoryLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/expense-category?${params}`)
      .then((res) => {
        dispatch({
          type: GET_EXPENSE_CATEGORY_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteExpenseCategory = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/payment/v1/auth/admin/expense-category/${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_EXPENSE_CATEGORY,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createExpenseCategory = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/payment/v1/auth/admin/expense-category`, values)
      .then((res) => {
        dispatch({
          type: CREATE_EXPENSE_CATEGORY,
        });
        resolve(res);
        createNotification('success', 'Success');
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        createNotification('error', "Something's wrong");
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateExpenseCategory = (id, values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/payment/v1/auth/admin/expense-category/${id}`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_EXPENSE_CATEGORY,
        });
        createNotification('success', 'Success');
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        createNotification('error', "Something's wrong");
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getExpenseCategoryDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/payment/v1/auth/admin/expense-category/${id}`)
      .then((res) => {
        dispatch({
          type: GET_EXPENSE_CATEGORY_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
