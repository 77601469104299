import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import { getUserLists, getAllUserLists, userUpdateData, blockUser, activeUser } from '../../../actions/user';
import { DEFAULT_PAGE_SIZE, USER_STATUS_ENUM } from '../../../utils/constants';
import { formatDateTime, createNotification, getUserId } from '../../../utils/helpers';
import SuspendModal from './Suspend';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        field_name: this.props.type ? 'type,status' : 'status',
        field_value: this.props.type ? `${this.props.type},1` : this.props.status,
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      userList: [],
      allUsers: [],
      total_items: 0,
      user: null,
      loading: false,
      csvData: [],
      loadingCsv: false,
    };
  }

  getUsers = () => {
    this.props.getAllUserLists({
      field_name: this.props.type ? 'type,status' : 'status',
      field_value: this.props.type ? `${this.props.type},1` : this.props.status,
      page: 1,
      page_size: this.state.total_items,
    });
  };

  generateCSV = () => {
    const result = [['name', 'email']];
    this.state.allUsers.forEach((el) => {
      result.push([el.name, el.email]);
    });
    this.setState({ csvData: result });
  };

  handleDisable = async (values, status) => {
    const confirm = window.confirm(`Are you sure ${USER_STATUS_ENUM[status]} this user?`);
    if (confirm) {
      const updateUser = values;
      updateUser.status = status;
      const userList = this.state.userList;
      userList.forEach((el) => {
        if (el.id == values.id) {
          el['status'] = status;
        }
      });
      this.setState({
        ...this.state,
        userList,
      });
      if (status == 1) {
        await this.props.activeUser(updateUser.id);
      } else {
        await this.props.blockUser(updateUser.id, this.state.suspend_day * 1);
        document.querySelector('#modalClose').click();
      }
      this.props.getUserLists({
        ...this.state.filter,
      });
    }
    return false;
  };

  handleOk = (suspend_day) => {
    if (suspend_day >= 0) {
      this.setState(
        {
          suspend_day,
        },
        () => {
          this.handleDisable(this.state.user, 0);
        },
      );
    } else {
      createNotification('error', 'Suspend Day needs to more than 0.');
    }
  };

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getUserLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  searchUser = (keyword) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          field_name: this.props.type ? 'type,status,keyword' : 'status,keyword',
          field_value: this.props.type
            ? `${this.props.type},1,${keyword.toLowerCase()}`
            : `${this.props.status},${keyword.toLowerCase()}`,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.users.allUsers !== this.props.users.allUsers) {
      this.setState(
        {
          ...this.state,
          allUsers: nextProps.users.allUsers.users,
          loadingCsv: false,
        },
        () => {
          this.generateCSV();
        },
      );
    }
    if (nextProps.users.users !== this.props.users.users) {
      this.setState(
        {
          ...this.state,
          userList: nextProps.users.users.users,
          total_items: nextProps.users.users.total_items,
        },
        () => {
          this.getUsers();
        },
      );
    }
    if (this.props.type !== nextProps.type || nextProps.status !== this.props.status) {
      this.setState(
        {
          ...this.state,
          filter: {
            field_name: nextProps.type ? 'type,status' : 'status',
            field_value: nextProps.type ? `${nextProps.type},1` : nextProps.status,
            page: 1,
            page_size: DEFAULT_PAGE_SIZE,
          },
        },
        () => {
          this.paginationHandler({ selected: 0 });
        },
      );
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Users</li>
          </ol>
        </nav>

        <h2 className="text-bold text-body-emphasis mb-5">Users</h2>
        <div id="members" className="table-list">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <DebounceInput
                    placeholder="Search"
                    aria-label="Search"
                    minLength={4}
                    debounceTimeout={300}
                    className="form-control search-input search"
                    onChange={(event) => this.searchUser(event.target.value)}
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  <CSVLink data={this.state.csvData} filename={'users.csv'}>
                    {this.state.loadingCsv ? 'Loading csv...' : 'Export'}
                  </CSVLink>
                </button>
                <a href="users/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add member
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="id"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      USER ID
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      USERNAME
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="slug"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      SLUG
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="email"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      EMAIL
                    </th>
                    {['Inactive User', 'Blocked User'].indexOf(this.props.role) < 0 && (
                      <th
                        className="sort align-middle text-start"
                        scope="col"
                        data-sort="mobile_number"
                        style={{ width: '5%', minWidth: '100px' }}
                      >
                        ROLE
                      </th>
                    )}
                    {['Inactive User', 'Blocked User'].indexOf(this.props.role) < 0 && (
                      <th
                        className="sort align-middle text-start"
                        scope="col"
                        data-sort="last_active"
                        style={{ width: '5%', minWidth: '150px' }}
                      >
                        LAST ACTIVE
                      </th>
                    )}
                    {['Blocked User'].indexOf(this.props.role) >= 0 && (
                      <>
                        <th
                          className="sort align-middle text-start"
                          scope="col"
                          data-sort="last_active"
                          style={{ width: '5%', minWidth: '200px' }}
                        >
                          DATE START
                        </th>
                        <th
                          className="sort align-middle text-start"
                          scope="col"
                          data-sort="last_active_end"
                          style={{ width: '5%', minWidth: '200px' }}
                        >
                          DATE END
                        </th>
                        <th
                          className="sort align-middle text-start"
                          scope="col"
                          data-sort="suspended_day"
                          style={{ width: '5%', minWidth: '200px' }}
                        >
                          NUMBER OF DAYS LOCKED
                        </th>
                      </>
                    )}
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="joined"
                      style={{ width: '19%', minWidth: '200px' }}
                    >
                      JOINED
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      style={{ width: '19%', minWidth: '200px' }}
                    ></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {(this.state.userList || []).map((user) => (
                    <tr class="hover-actions-trigger btn-reveal-trigger position-static">
                      <td class="fs-9 align-middle ps-0 py-3">
                        <div class="form-check mb-0 fs-8">
                          <input class="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                        </div>
                      </td>
                      <td class="id align-middle white-space-nowrap text-uppercase">{getUserId(user.id)}</td>
                      <td class="customer align-middle white-space-nowrap">
                        <a class="d-flex align-items-center text-body text-hover-1000" href="#!">
                          <div class="avatar avatar-m">
                            <img
                              class="rounded-circle"
                              src={
                                user.avatar ||
                                'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                              }
                              alt=""
                            />
                          </div>
                          <h6 class="username mb-0 ms-3 fw-semibold">{user.name}</h6>
                        </a>
                      </td>
                      <td class="slug align-middle white-space-nowrap">{user.slug}</td>
                      <td class="email align-middle white-space-nowrap">
                        <a class="fw-semibold" href={`mailto:${user.email}`}>
                          {user.email}
                        </a>
                      </td>
                      {['Inactive User', 'Blocked User'].indexOf(this.props.role) < 0 && (
                        <td class="mobile_number align-middle white-space-nowrap">
                          <a class="fw-bold text-body-emphasis" href="#">
                            {this.props.role}
                          </a>
                        </td>
                      )}
                      {['Inactive User', 'Blocked User'].indexOf(this.props.role) < 0 && (
                        <td class="last_active align-middle white-space-nowrap text-body-tertiary">
                          {user.last_seen ? moment(new Date(user.last_seen * 1000).getTime()).fromNow() : ''}
                        </td>
                      )}
                      {['Blocked User'].indexOf(this.props.role) >= 0 && (
                        <>
                          <td class="last_active align-middle white-space-nowrap text-body-tertiary">
                            {formatDateTime(user.updated_at)}
                          </td>
                          <td class="last_active align-middle white-space-nowrap text-body-tertiary">
                            {user?.suspend_day
                              ? formatDateTime(moment(new Date(user.updated_at)).add(user?.suspend_day || 0, 'days'))
                              : 'Forever'}
                          </td>
                          <td class="last_active align-middle white-space-nowrap text-body-tertiary">
                            {user?.suspend_day || 'Forever'}
                          </td>
                        </>
                      )}
                      <td class="joined align-middle white-space-nowrap text-body-tertiary">
                        {formatDateTime(user.created_at)}
                      </td>
                      <td class="joined align-middle white-space-nowrap text-body-tertiary text-start">
                        {user.status === 1 && (
                          <a class="px-2" href={`users/edit/${user.id}`}>
                            Edit
                          </a>
                        )}
                        {user.status === 1 ? (
                          <a
                            class="text-danger"
                            href="#!"
                            aria-hidden="true"
                            data-bs-toggle="modal"
                            data-bs-target="#disputeModal"
                            onClick={() =>
                              this.setState({
                                user,
                              })
                            }
                          >
                            Block
                          </a>
                        ) : (
                          <a class="text-danger" href="#!" onClick={() => this.handleDisable(user, 1)}>
                            {user.status === 0 ? 'Active' : 'Unblock'}
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9 mt-3">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body">
                  View {this.state.filter.page * DEFAULT_PAGE_SIZE} of {this.state.total_items}
                </p>
              </div>
              <div class="col-auto d-flex">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={this.state.filter.page - 1}
                  pageCount={Math.ceil(this.state.total_items / DEFAULT_PAGE_SIZE)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.paginationHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <SuspendModal handleOk={this.handleOk} />
      </>
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getUserLists: PropTypes.func.isRequired,
  userUpdateData: PropTypes.func.isRequired,
  activeUser: PropTypes.func.isRequired,
  blockUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, { getUserLists, getAllUserLists, userUpdateData, blockUser, activeUser })(
  Users,
);
