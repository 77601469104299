import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import { GET_ERRORS, GET_ROOM_LIST, ARCHIVE_ROOM } from './types';

// Get List of CHAT ROOM
export const getRoomLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/chat/list-rooms?${params}`)
      .then((res) => {
        dispatch({
          type: GET_ROOM_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteRoom = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/ticket-management/v1/auth/admin/tickets/${id}`, {})
      .then((res) => {
        dispatch({
          type: ARCHIVE_ROOM,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const removeMessage = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/ticket-management/v1/auth/admin/tickets/${id}`, {})
      .then((res) => {
        dispatch({
          type: ARCHIVE_ROOM,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
