import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import advanceAjaxTableInit from '../../../utils/advanceAjaxTableInit';

import { getSubscriberLists } from '../../../actions/subscriber';
import { formatDateTime } from '../../../utils/helpers';

const statuses = ['UnKnown', 'Pending', 'Subscribed', 'Unsubscribed', 'Cleaned'];

const statusesColor = ['default', 'warning', 'success', 'danger', 'danger'];

class Subscribers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: 10,
      },
      total_items: 0,
      subscribers: [],
      csvData: [],
      loadingCsv: false,
    };
    this.paginationHandler = this.paginationHandler.bind(this);
  }
  generateCSV = (items) => {
    const result = [['email']];
    items.forEach((el) => {
      result.push([el.email]);
    });
    this.setState({ csvData: result, loadingCsv: false });
  };
  generateTable = () => {
    const options = {
      page: 12,
      valueNames: ['optin_date', 'email'],
      pagination: {
        item: "<li><button class='page' type='button'></button></li>",
      },
    };

    window.tableList = advanceAjaxTableInit(options);
  };
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getSubscriberLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
    this.generateTable();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newsletter !== this.props.newsletter) {
      this.setState(
        {
          ...this.state,
          subscribers: nextProps.newsletter.subscribers.data,
          loadingCsv: true,
        },
        () => {
          this.generateCSV(nextProps.newsletter.subscribers.data);
        },
      );
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Marketplace</a>
            </li>
            <li className="breadcrumb-item active">Subscribers</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">Subscribers</h2>
        <div id="advanceTable" data-list='{"page":12,"pagination":{"innerWindow":2,"left":1,"right":1}}'>
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box"></div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  <CSVLink data={this.state.csvData} filename={'subscribers.csv'}>
                    {this.state.loadingCsv ? 'Loading csv...' : 'Export'}
                  </CSVLink>
                </button>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th className="sort align-middle text-start" scope="col" data-sort="created_date">
                      OPTIN DATE
                    </th>
                    <th className="sort align-middle text-start" scope="col" data-sort="seller">
                      EMAIL
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      STATUS
                    </th>
                    <th className="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {(this.state.subscribers || []).length > 0 ? (
                    (this.state.subscribers || []).map((subscriber) => (
                      <tr key={subscriber.email} className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="fs-9 align-middle ps-0 py-3">
                          <div className="form-check mb-0 fs-8">
                            <input className="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                          </div>
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          {formatDateTime(new Date(subscriber?.optin_date).getTime())}
                        </td>
                        <td className="seller align-middle white-space-nowrap">
                          <span className="fw-bold text-body-emphasis">{subscriber?.email}</span>
                        </td>
                        <td className="mobile_number align-middle white-space-nowrap">
                          <span
                            class={`badge badge-phoenix fs-10 badge-phoenix-${
                              statusesColor[subscriber.status]
                            } d-inline-flex align-items-center`}
                          >
                            {statuses[subscriber.status]}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div class="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body" data-list-info="data-list-info"></p>
                <a class="fw-semibold" href="#!" data-list-view="*">
                  View all
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
                <a class="fw-semibold d-none" href="#!" data-list-view="less">
                  View Less
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
              </div>
              <div class="col-auto d-flex">
                <button class="page-link" data-list-pagination="prev">
                  <span class="fas fa-chevron-left"></span>
                </button>
                <ul class="mb-0 pagination"></ul>
                <button class="page-link pe-0" data-list-pagination="next">
                  <span class="fas fa-chevron-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Subscribers.propTypes = {
  auth: PropTypes.object.isRequired,
  getSubscriberLists: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  newsletter: state.newsletter,
  loaded: state.loadingBar.sectionBar === 1 ? true : false,
});

export default connect(mapStateToProps, {
  getSubscriberLists,
})(Subscribers);
