import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logoutUser, clearCurrentProfile } from '../../actions/auth';
import Document from '../layout/Document';
import Analytics from './Analytics';

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props.auth.user;
    return (
      <Document title="Dashboard Administrator" className="dashboard-page">
        <Analytics />
      </Document>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps, { logoutUser, clearCurrentProfile })(Dashboard);
