import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { DebounceInput } from 'react-debounce-input';

import { getAffPayoutsLists, updateAffPayout } from '../../../actions/transaction';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { formatDate, convertCurrency } from '../../../utils/helpers';

const filterKey = {
  1: 'Upcomming',
  2: 'Paid',
};

const statuses = ['UnKnown', 'Upcomming', 'Paid'];

const statusesColor = ['default', 'warning', 'success'];

class Payouts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        total_page: 0,
        page_size: DEFAULT_PAGE_SIZE,
        order_id: '',
      },
      transactionList: [],
      total_items: 0,
      activeStatus: '',
    };
    this.setActiveStatus = this.setActiveStatus.bind(this);
  }

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getAffPayoutsLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  handleApprove = async (id) => {
    const confirm = window.confirm('Are you sure approve this payout?');
    if (confirm) {
      await this.props.updateAffPayout(id);
      this.paginationHandler({ selected: this.state.filter.page - 1 });
    }
    return false;
  };
  setActiveStatus(status) {
    this.setState(
      {
        activeStatus: status,
        filter: {
          status,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.payouts !== this.props.payouts) {
      this.setState({
        ...this.state,
        transactionList: nextProps.payouts?.data?.payouts,
        total_items: nextProps.payouts?.data?.total_items,
        total_page: nextProps.payouts?.data?.num_of_page,
      });
    }
  }
  searchItem = (order_id) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          order_id,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  };
  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Payouts</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">Payouts</h2>
        <div id="members" className="table-list">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto d-flex flex-row">
              <div className="search-box me-1">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <DebounceInput
                    placeholder="Search"
                    aria-label="Search"
                    minLength={4}
                    debounceTimeout={300}
                    className="form-control search-input search"
                    onChange={(event) => this.searchItem(event.target.value)}
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
              <select
                className="form-select1"
                id="organizerSingle"
                data-choices="data-choices"
                data-options='{"removeItemButton":false,"placeholder":true}'
                onChange={(e) => this.setActiveStatus(e.target.value)}
              >
                <option selected={this.state.activeStatus == ''} value="">
                  All
                </option>
                {Object.keys(filterKey).map((value) => (
                  <option value={value} selected={this.state.activeStatus == value}>
                    {filterKey[value]}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>Export
                </button>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="date"
                      style={{ width: '15%', maxWidth: '150px' }}
                    >
                      REQUEST DATE
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="_id"
                      style={{ width: '15%', maxWidth: '150px' }}
                    >
                      PAYOUT ID
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="payout"
                      style={{ width: '15%', maxWidth: '150px' }}
                    >
                      PAYOUT
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      STATUS
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="seller"
                      style={{ width: '15%', minWidth: '150px' }}
                    >
                      SELLER
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="amount"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      AMOUNT
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="fee"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      FEE
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="total"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      TOTAL
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {this.state.transactionList?.length > 0 ? (
                    this.state.transactionList.map((method, index) => {
                      const { seller_info, payout_method, amount } = method;
                      let infoHTML = '';
                      if (seller_info && payout_method) {
                        const payout_info = seller_info?.payout_info ? JSON.parse(seller_info?.payout_info) : {};
                        const exclude = ['selected', 'payout', 'fee', 'is_default'];

                        if (Array.isArray(payout_info)) {
                          infoHTML = '';
                          infoHTML += `<strong className="text-capitalize">Payout Method: </strong>${payout_method.name} <br/>`;
                          const find = payout_info.filter((m) => m.payout_id === payout_method?._id);
                          if (find.length > 0) {
                            Object.keys(find[0]).forEach((el) => {
                              const findKey = exclude.filter((m) => el.indexOf(m) >= 0);
                              if (findKey.length === 0)
                                infoHTML += `<strong className="text-capitalize">${el.replaceAll('_', ' ')}: </strong>${
                                  find[0][el]
                                } <br/>`;
                            });
                          }
                        } else {
                          Object.keys(payout_info).forEach((el) => {
                            const findKey = exclude.filter((m) => el.indexOf(m) >= 0);
                            if (findKey.length === 0)
                              infoHTML += `<strong className="text-capitalize">${el.replaceAll('_', ' ')}: </strong> ${
                                payout_info[el]
                              } <br/>`;
                          });
                        }
                      }

                      const subtotal = payout_method
                        ? (amount * payout_method.fee || 0) / 100 + payout_method.additional_fee || 0
                        : 0;
                      const total = amount - subtotal;

                      return (
                        <tr key={index} className="hover-actions-trigger btn-reveal-trigger position-static">
                          <td className="date align-middle white-space-nowrap">
                            {formatDate(method.created_time * 1000)}
                          </td>
                          <td className="_id align-middle white-space-nowrap">{method._id}</td>
                          <td className="payout align-middle white-space-nowrap">
                            <div dangerouslySetInnerHTML={{ __html: infoHTML }} />
                          </td>
                          <td className="status align-middle white-space-nowrap">
                            <span
                              className={`badge badge-phoenix fs-10 badge-phoenix-${
                                statusesColor[method.status]
                              } d-inline-flex align-items-center`}
                            >
                              {statuses[method.status]}
                            </span>
                          </td>
                          <td className="align-middle white-space-nowrap">
                            <h6 className="seller_name mb-0 fw-semibold">{method.seller_info.seller_name}</h6>
                          </td>
                          <td className="amount align-middle white-space-nowrap">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertCurrency(method.amount, false, this.props.settings?.data?.sgd),
                              }}
                            />
                          </td>
                          <td className="fee align-middle white-space-nowrap">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: convertCurrency(subtotal, false, this.props.settings?.data?.sgd),
                              }}
                            />
                          </td>
                          <td className="amount align-middle white-space-nowrap">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertCurrency(total, false, this.props.settings?.data?.sgd),
                              }}
                            />
                          </td>
                          <td className="action align-middle white-space-nowrap text-body-tertiary text-end">
                            {method.status == 1 && (
                              <a className="px-2 text-success" href="#" onClick={() => this.handleApprove(method._id)}>
                                Approve
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div className="d-flex justify-content-center mt-3">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={0}
                  pageCount={this.state.total_page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.paginationHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Payouts.propTypes = {
  auth: PropTypes.object.isRequired,
  payouts: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getAffPayoutsLists: PropTypes.func.isRequired,
  updateAffPayout: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  payouts: state.transactions.payouts,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getAffPayoutsLists,
  updateAffPayout,
})(Payouts);
