import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logoutUser, clearCurrentProfile } from '../../actions/auth';
import Document from '../layout/Document';

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props.auth.user;
    return (
      <Document title="Dashboard Administrator" className="dashboard-page">
        <div
          id="tableExample4"
          data-list='{"valueNames":["name","email","payment"],"page":5,"pagination":true,"filter":{"key":"payment"}}'
        >
          <div className="row justify-content-end g-0">
            <div className="col-auto px-3">
              <select className="form-select form-select-sm mb-3" data-list-filter="data-list-filter">
                <option selected="" value="">
                  Select payment status
                </option>
                <option value="Pending">Pending</option>
                <option value="Success">Success</option>
                <option value="Blocked">Blocked</option>
              </select>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-sm fs-9 mb-0">
              <thead>
                <tr className="bg-body-highlight">
                  <th className="sort border-top border-translucent ps-3" data-sort="name">
                    Customer
                  </th>
                  <th className="sort border-top border-translucent" data-sort="email">
                    Email
                  </th>
                  <th className="sort border-top border-translucent text-end pe-3" data-sort="payment">
                    Payment
                  </th>
                </tr>
              </thead>
              <tbody className="list">
                <tr>
                  <td className="align-middle ps-3 name">Sylvia Plath</td>
                  <td className="align-middle email">john@gmail.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-success">
                      <span className="fw-bold">Success</span>
                      <span className="ms-1 fas fa-check"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">Homer</td>
                  <td className="align-middle email">sylvia@mail.ru</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-warning">
                      <span className="fw-bold">Pending</span>
                      <span className="ms-1 fas fa-stream"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">Edgar Allan Poe</td>
                  <td className="align-middle email">edgar@yahoo.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-secondary">
                      <span className="fw-bold">Blocked</span>
                      <span className="ms-1 fas fa-ban"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">William Butler Yeats</td>
                  <td className="align-middle email">william@gmail.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-success">
                      <span className="fw-bold">Success</span>
                      <span className="ms-1 fas fa-check"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">Rabindranath Tagore</td>
                  <td className="align-middle email">tagore@twitter.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-warning">
                      <span className="fw-bold">Pending</span>
                      <span className="ms-1 fas fa-stream"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">Emily Dickinson</td>
                  <td className="align-middle email">emily@gmail.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-secondary">
                      <span className="fw-bold">Blocked</span>
                      <span className="ms-1 fas fa-ban"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">Giovanni Boccaccio</td>
                  <td className="align-middle email">giovanni@outlook.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-warning">
                      <span className="fw-bold">Pending</span>
                      <span className="ms-1 fas fa-stream"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">Oscar Wilde</td>
                  <td className="align-middle email">oscar@hotmail.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-success">
                      <span className="fw-bold">Success</span>
                      <span className="ms-1 fas fa-check"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">John Doe</td>
                  <td className="align-middle email">doe@gmail.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-success">
                      <span className="fw-bold">Success</span>
                      <span className="ms-1 fas fa-check"></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-3 name">Emma Watson</td>
                  <td className="align-middle email">emma@gmail.com</td>
                  <td className="align-middle payment text-end py-3 pe-3">
                    <div className="badge badge-phoenix fs-10 badge-phoenix-warning">
                      <span className="fw-bold">Pending</span>
                      <span className="ms-1 fas fa-stream"></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button className="page-link" data-list-pagination="prev">
              <span className="fas fa-chevron-left"></span>
            </button>
            <ul className="mb-0 pagination"></ul>
            <button className="page-link pe-0" data-list-pagination="next">
              <span className="fas fa-chevron-right"></span>
            </button>
          </div>
        </div>
      </Document>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps, { logoutUser, clearCurrentProfile })(Dashboard);
