import {
  GET_PRODUCT_LIST,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_PRODUCT,
} from "../actions/types";

const initialState = {
  products: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_LIST:
      return {
        ...state,
        products: action.payload,
      };
    case UPDATE_PRODUCT:
    case CREATE_PRODUCT:
    case DELETE_PRODUCT:
      return {
        ...state,
      };
    default:
      return state;
  }
}
