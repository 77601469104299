import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import {
  GET_ERRORS,
  GET_GENERAL_REPORTS,
  GET_SELLER_REPORTS,
  GET_BUYER_REPORTS,
  GET_ANNUAL_GENERAL_REPORTS,
  GET_SELLER_WALLETS,
  UPDATE_SELLER_WALLETS,
} from './types';

export const getGeneralReports = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/analytics?${params}`)
      .then((res) => {
        dispatch({
          type: GET_GENERAL_REPORTS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getAnnualReports = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/analytics?${params}`)
      .then((res) => {
        dispatch({
          type: GET_ANNUAL_GENERAL_REPORTS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getTopSellersReports = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/analytics/top-sellers?${params}`)
      .then((res) => {
        dispatch({
          type: GET_SELLER_REPORTS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getWalletsReports = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/wallets?${params}`)
      .then((res) => {
        dispatch({
          type: GET_SELLER_WALLETS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getTopBuyersReports = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/analytics/top-buyers?${params}`)
      .then((res) => {
        dispatch({
          type: GET_BUYER_REPORTS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateAnalyticReports = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/reports/update?${params}`)
      .then((res) => {
        dispatch({
          type: UPDATE_SELLER_WALLETS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateWalletsReports = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/wallets/update?${params}`)
      .then((res) => {
        dispatch({
          type: UPDATE_SELLER_WALLETS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
