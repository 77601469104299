import _ from 'lodash';

import {
  GET_SELLER_LIST,
  UPDATE_SELLER,
  GET_SELLER_DETAIL,
  NOTIFICATION_LIST,
  UPDATE_NOTIFICATION,
} from '../actions/types';

const initialState = {
  sellers: {},
  notifications: {},
  seller: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_LIST:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_SELLER_LIST:
      return {
        ...state,
        sellers: action.payload,
      };
    case GET_SELLER_DETAIL:
      return {
        ...state,
        seller: action.payload,
      };
    case UPDATE_NOTIFICATION:
    case UPDATE_SELLER:
      return {
        ...state,
      };
    default:
      return state;
  }
}
