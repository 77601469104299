import _ from 'lodash'
import jwtDecode from 'jwt-decode'
import rest from '../utils/rest'
import config from '../config'
import {
    setAuthToken,
    beforeRequest,
    beforeResponse
} from '../utils/helpers'
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    CLEAR_CURRENT_PROFILE,
    ADMIN_ACCESS_ENUM
} from './types';

// Login - Get User Token
export const loginUser = userData => dispatch => {
    dispatch(beforeRequest())

    return new Promise((resolve, reject) => {
        let decoded;
        rest
            .post('account/v1/login', userData)
            .then(res => {
                // Save to localStorage
                const {
                    access_token
                } = res.data;

                // Decode token to get user data
                decoded = jwtDecode(access_token);

                const intersection = decoded.tags.filter((x) => ADMIN_ACCESS_ENUM.includes(x));
                if (intersection.length > 0) {
                    // Set token to ls
                    sessionStorage.setItem(config.tokenKey, access_token);
                    // Set token to Auth header
                    setAuthToken(access_token);
                    
                    // Set current user
                    dispatch(setCurrentUser(decoded))
                    resolve(res)
                } else {
                    dispatch({
                        type: GET_ERRORS,
                        payload: "You don't have access!"
                    })
                    reject("You don't have access!")
                }
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response ? err.response : null
                })
                reject(err)
            })
            .then(function () {
                dispatch(beforeResponse())
            })
    });

};

// Set logged in user
export const setCurrentUser = (decoded = {}) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

// Clear profile
export const clearCurrentProfile = () => {
    return {
        type: CLEAR_CURRENT_PROFILE
    };
};


// Log user out
export const logoutUser = () => dispatch => {
    dispatch(beforeRequest())
    // Remove token from sessionStorage
    sessionStorage.removeItem(config.tokenKey);
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch(setCurrentUser());

    dispatch(beforeResponse())
};