import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';

import { createGameType, updateGameType, getGameType } from '../../../actions/gameType';
import { withRouter } from '../../../withRouter';

const requiredFields = ['name'];

class FormGameTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Game Type' : 'Create Game Type',
      gameType: {},
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    this.setState({
      gameType: {
        ...this.state.gameType,
        [name]: value,
      },
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.gameType, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      if (this.props.router.params.id) {
        this.props.updateGameType(this.props.router.params.id, this.state.gameType);
      } else {
        this.props.createGameType(this.state.gameType);
      }
      createNotification('success', lang('success.update'));
    }
  }

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getGameType(this.props.router.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.gameType !== this.props.gameType) {
      this.setState({ gameType: nextProps.gameType.data });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <form className="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          <div className="col-md-12">
            <label className="form-label" for="inputFullName">
              Name
            </label>
            <input
              className="form-control"
              id="inputFullName"
              type="text"
              required="required"
              value={this.state.gameType?.name}
              onChange={(e) => this.updateState('name', e.target.value)}
            />
            <div className="invalid-feedback">Please enter name</div>
          </div>
          <div className="col-12">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormGameTypes.propTypes = {
  auth: PropTypes.object.isRequired,
  gameType: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createGameType: PropTypes.func.isRequired,
  updateGameType: PropTypes.func.isRequired,
  getGameType: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  gameType: state.gameTypes.gameType,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createGameType,
  updateGameType,
  getGameType,
})(withRouter(FormGameTypes));
