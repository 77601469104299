import _ from 'lodash';

import { GET_ROOM_LIST, ARCHIVE_ROOM } from '../actions/types';

const initialState = {
  rooms: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ROOM_LIST:
      return {
        ...state,
        rooms: action.payload,
      };
    case ARCHIVE_ROOM:
      return {
        ...state,
      };
    default:
      return state;
  }
}
