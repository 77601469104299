import {
  GET_GAME_LIST,
  GET_GAME_DETAIL,
  UPDATE_GAME,
  DELETE_GAME,
  CREATE_GAME,
  CREATE_GAME_VARIANT,
  UPDATE_GAME_VARIANT,
  DELETE_GAME_VARIANT,
  CREATE_GAME_PRODUCT_TYPE,
  DELETE_GAME_PRODUCT_TYPE,
} from '../actions/types';

const initialState = {
  games: {},
  game: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GAME_LIST:
      return {
        ...state,
        games: action.payload,
      };
    case CREATE_GAME:
    case GET_GAME_DETAIL:
      return {
        ...state,
        game: action.payload,
      };
    case CREATE_GAME_PRODUCT_TYPE:
    case DELETE_GAME_PRODUCT_TYPE:
    case CREATE_GAME_VARIANT:
    case UPDATE_GAME_VARIANT:
    case DELETE_GAME_VARIANT:
    case UPDATE_GAME:
    case DELETE_GAME:
      return {
        ...state,
      };
    default:
      return state;
  }
}
