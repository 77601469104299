import _ from 'lodash';

import {
  GET_SUPPORT_LIST,
  UPDATE_SUPPORT,
  DELETE_SUPPORT,
  GET_SUPPORT_DETAIL,
  GET_SUPPORT_RESOLVE,
  CREATE_RESOLVE,
  GET_SUPPORT_REPLIES,
} from '../actions/types';

const initialState = {
  supports: {},
  support: {},
  resolve: {},
  replies: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUPPORT_LIST:
      return {
        ...state,
        supports: action.payload,
      };
    case GET_SUPPORT_DETAIL:
      return {
        ...state,
        support: action.payload,
      };
    case GET_SUPPORT_REPLIES:
      return {
        ...state,
        replies: action.payload,
      };
    case GET_SUPPORT_RESOLVE:
      return {
        ...state,
        resolve: action.payload,
      };
    case UPDATE_SUPPORT:
    case DELETE_SUPPORT:
    case CREATE_RESOLVE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
