import {
  GET_PAYOUT_METHOD_LIST,
  GET_PAYOUT_METHOD_DETAIL,
  UPDATE_PAYOUT_METHOD,
  DELETE_PAYOUT_METHOD,
  CREATE_PAYOUT_METHOD,
} from "../actions/types";

const initialState = {
  payoutMethods: {},
  payoutMethod: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAYOUT_METHOD_LIST:
      return {
        ...state,
        payoutMethods: action.payload,
      };
    case GET_PAYOUT_METHOD_DETAIL:
      return {
        ...state,
        payoutMethod: action.payload,
      };
    case CREATE_PAYOUT_METHOD:
    case UPDATE_PAYOUT_METHOD:
    case DELETE_PAYOUT_METHOD:
      return {
        ...state,
      };
    default:
      return state;
  }
}
