import _ from 'lodash';

import {
  GET_GENERAL_REPORTS,
  GET_BUYER_REPORTS,
  GET_SELLER_REPORTS,
  GET_ANNUAL_GENERAL_REPORTS,
  GET_SELLER_WALLETS,
  UPDATE_SELLER_WALLETS,
} from '../actions/types';

const initialState = {
  general: [],
  sellers: [],
  buyers: [],
  annual: [],
  wallets: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ANNUAL_GENERAL_REPORTS:
      return {
        ...state,
        annual: action.payload,
      };
    case GET_GENERAL_REPORTS:
      return {
        ...state,
        general: action.payload,
      };
    case GET_SELLER_REPORTS:
      return {
        ...state,
        sellers: action.payload,
      };
    case GET_BUYER_REPORTS:
      return {
        ...state,
        buyers: action.payload,
      };
    case GET_SELLER_WALLETS:
      return {
        ...state,
        wallets: action.payload,
      };
    case UPDATE_SELLER_WALLETS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
