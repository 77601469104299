import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getAnnualReports, updateAnalyticReports } from '../../../actions/report';
import { convertCurrency } from '../../../utils/helpers';

class Revenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: [],
      general: {},
      keys: [
        'total_revenue',
        'total_payout',
        'total_profit',
        'total_refund',
        'total_charge_back',
        'total_expense',
        'total_current_balance',
      ],
    };
  }
  componentDidMount() {
    this.props.getAnnualReports({ year: new Date().getFullYear() });
  }

  updateAnalytics = async () => {
    await this.props.updateAnalyticReports();
    this.props.getAnnualReports({ year: new Date().getFullYear() });
  };

  calculateCurrentBalance(report) {
    const revenue = report.total_revenue || 0;
    const payout = report.total_payout || 0;
    const chargeback = report.total_charge_back || 0;
    const expense = report.total_expense;
    return revenue - payout - chargeback - expense;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(this.props.settings?.data?.sgd);
    if (this.props.report !== nextProps.report) {
      const report = nextProps.report?.data || [];
      const reportList = report.map((item) => ({
        ...item,
        total_current_balance: this.calculateCurrentBalance(item),
      }));
      this.setState({
        report: reportList.sort((a, b) => a.month - b.month),
      });
    }
    if (this.props.month !== nextProps.month || this.props.year !== nextProps.year) {
      this.props.getAnnualReports({ year: nextProps.year });
    }
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center justify-content-between g-3 mb-6">
            <div className="col-12 col-md-auto">
              <h2 className="mb-0">Analytics by year</h2>
            </div>
            <div className="col-12 col-md-auto">
              <button className="btn btn-primary" onClick={this.updateAnalytics}>
                Update analytics
              </button>
            </div>
          </div>
          <div className="table-responsive scrollbar">
            <table className="reports-details-chart-table table table-sm fs-9 mb-0">
              <thead>
                <tr>
                  <th
                    className="align-middle pe- text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Year
                  </th>
                  <th
                    className="align-middle pe- text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Month
                  </th>
                  {this.state.keys.map(
                    (el) =>
                      [
                        'month',
                        'year',
                        'total_profile',
                        'total_products',
                        'total_new_customers',
                        'total_sales',
                      ].indexOf(el) < 0 && (
                        <th
                          key={el}
                          className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                          scope="col"
                          style={{ width: '15%' }}
                        >
                          {el.replaceAll('_', ' ')}
                        </th>
                      ),
                  )}
                </tr>
              </thead>
              <tbody className="list" id="report-data-body">
                {(this.state.report || []).length > 0 &&
                  this.state.report.map((item, index) => (
                    <tr key={index} className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="align-middle white-space-nowrap fw-semibold text-body-highlight py-2">
                        {item.year}
                      </td>
                      <td className="align-middle white-space-nowrap fw-semibold text-body-highlight py-2">
                        {`0${item.month}`.slice(0, 2)}
                      </td>
                      {this.state.keys.map(
                        (el) =>
                          [
                            'month',
                            'year',
                            'total_profile',
                            'total_products',
                            'total_new_customers',
                            'total_sales',
                          ].indexOf(el) < 0 && (
                            <td
                              key={el}
                              className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: convertCurrency(item[el] || 0, false, this.props.settings?.data?.sgd),
                                }}
                              />
                            </td>
                          ),
                      )}
                    </tr>
                  ))}
                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                  <td
                    colSpan={2}
                    className="text-danger align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2"
                  >
                    Sum
                  </td>
                  {this.state.keys.map(
                    (el) =>
                      [
                        'month',
                        'year',
                        'total_profile',
                        'total_products',
                        'total_new_customers',
                        'total_sales',
                      ].indexOf(el) < 0 && (
                        <td
                          key={el}
                          className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2"
                        >
                          <span
                            className="text-danger"
                            dangerouslySetInnerHTML={{
                              __html: convertCurrency(
                                _.sumBy(this.state.report, el) || 0,
                                false,
                                this.props.settings?.data?.sgd,
                              ),
                            }}
                          />
                        </td>
                      ),
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

Revenue.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  report: state.report.annual,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, { getAnnualReports, updateAnalyticReports })(Revenue);
