import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import countryList from 'react-select-country-list';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';
import config from '../../../config';

import {
  createPaymentMethod,
  updatePaymentMethod,
  getPaymentMethodDetail,
  getPaymentMethodLists,
} from '../../../actions/payment';
import { withRouter } from '../../../withRouter';

const requiredFields = ['name', 'fee_charge_seller', 'fee_charge_buyer', 'provider_platform'];

const countries = countryList().getData();

const providers = {
  hitpay: ['paynow_online', 'card', 'wechat', 'alipay', 'applepay', 'googlepay'],
  triplea: [],
  tazapay: [],
  wallet: [],
  stripe: [],
  binance: [],
};

class FormPaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Payment Method' : 'Create Payment Method',
      paymentMethod: {},
      selectedCountries: [],
      image_url: '',
      icon: null,
    };
    this.handleSave = this.handleSave.bind(this);
    this.onSelectDropdown = this.onSelectDropdown.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  updateState(name, value) {
    this.setState({
      paymentMethod: {
        ...this.state.paymentMethod,
        [name]: value,
      },
    });
  }

  onSelectDropdown(e) {
    const options = e.target.options;
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    this.setState({
      selectedCountries: selectedValues,
    });
  }

  uploadImage = async (file, callback) => {
    const body = new FormData();
    body.append('file', file);
    body.append('path', `/categories/`);
    body.append('type_upload', 'product');
    body.append('name', 'product');
    const me = this;

    fetch(`${config.url}/assets/v1/auth/admin/asset/upload`, {
      method: 'POST',
      body,
      headers: { 'access-token': sessionStorage.getItem(config.tokenKey) },
    })
      .then((response) => response.json())
      .then((json) => callback(json?.data?.img_urls[0]))
      .catch((err) => console.log(err));
  };

  handleImageUpload = (file, callback) => {
    var fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;
    const me = this;
    var extension = file.name.split('.').pop().toLowerCase();

    if (file && fileMb <= 10 && fileTypes.indexOf(extension) >= 0) {
      const reader = new FileReader();

      reader.onload = function (e) {
        me.uploadImage(file, callback);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  handleUploadIcon = (callback) => {
    const file = this.state.icon;
    if (file) {
      this.handleImageUpload(file, (result) => {
        this.setState({ image_url: result }, () => callback());
      });
    } else {
      callback();
    }
  };

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.paymentMethod, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      const values = this.state.paymentMethod;
      values.fee_charge_buyer = values.fee_charge_buyer * 1;
      values.fee_charge_seller = values.fee_charge_seller * 1;
      values.description = window.tinymce.get('description').getContent();
      values.allowed_countries = (this.state.selectedCountries || []).length > 0 ? this.state.selectedCountries : null;

      this.handleUploadIcon(() => this.processData(values));
    }
  }

  processData = async (values) => {
    values.image_url = this.state.image_url;
    if (this.props.router.params.id) {
      await this.props.updatePaymentMethod(this.props.router.params.id, values);
    } else {
      await this.props.createPaymentMethod(values);
    }
    this.props.getPaymentMethodLists();
    createNotification('success', lang('success.update'));
  };

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getPaymentMethodDetail(this.props.router.params.id);
    }
    this.initImageUpload();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.paymentMethodDetail !== this.props.paymentMethodDetail) {
      this.setState({
        paymentMethod: nextProps.paymentMethodDetail.data,
        image_url: nextProps.paymentMethodDetail?.data?.image_url,
      });
      window.tinymce.get('description').setContent(nextProps.paymentMethodDetail.data.description);
      this.setState({
        selectedCountries: nextProps.paymentMethodDetail.data.allowed_countries,
      });
    }
  }

  initImageUpload() {
    window.Dropzone.autoDiscover = false;
    const Selector = {
      DROPZONE: '#dropzone',
      DZ_ERROR_MESSAGE: '.dz-error-message',
      DZ_PREVIEW: '.dz-preview',
      DZ_PROGRESS: '.dz-preview .dz-preview-cover .dz-progress',
      DZ_PREVIEW_COVER: '.dz-preview .dz-preview-cover',
    };

    const ClassName = {
      DZ_FILE_PROCESSING: 'dz-file-processing',
      DZ_FILE_COMPLETE: 'dz-file-complete',
      DZ_COMPLETE: 'dz-complete',
      DZ_PROCESSING: 'dz-processing',
    };

    const Events = {
      ADDED_FILE: 'addedfile',
      REMOVED_FILE: 'removedfile',
      COMPLETE: 'complete',
    };

    const item = document.querySelector(Selector.DROPZONE);
    const that = this;
    const options = {
      url: '/assets/php/',
      addRemoveLinks: false,
      previewsContainer: item.querySelector(Selector.DZ_PREVIEW),
      previewTemplate: item.querySelector(Selector.DZ_PREVIEW).innerHTML,
      thumbnailWidth: null,
      thumbnailHeight: null,
      maxFilesize: 1,
      autoProcessQueue: false,
      filesizeBase: 1000,
      error(file, message) {
        if (file.previewElement) {
          file.previewElement.classList.add('dz-error');
          if (typeof message !== 'string' && message.error) {
            message = message.error;
          }
          for (let node of file.previewElement.querySelectorAll('[data-dz-errormessage]')) {
            node.textContent = message;
          }
        }
      },
    };
    item.querySelector(Selector.DZ_PREVIEW).innerHTML = '';

    const dropzone = new window.Dropzone(Selector.DROPZONE, options);

    dropzone.on(Events.ADDED_FILE, (e) => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_FILE_COMPLETE);
      }
      item.classList.add(ClassName.DZ_FILE_PROCESSING);
      that.setState({ icon: e });
    });
    dropzone.on(Events.REMOVED_FILE, () => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_PROCESSING);
      }
      item.classList.add(ClassName.DZ_FILE_COMPLETE);
      that.setState({ icon: null });
    });
    dropzone.on(Events.COMPLETE, () => {
      if (item.querySelector(Selector.DZ_PREVIEW_COVER)) {
        item.querySelector(Selector.DZ_PREVIEW_COVER).classList.remove(ClassName.DZ_PROCESSING);
      }

      item.classList.add(ClassName.DZ_FILE_COMPLETE);
    });
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <form class="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputRank">
              Name
            </label>
            <input
              class="form-control"
              id="inputRank"
              type="text"
              required="required"
              value={this.state.paymentMethod?.name}
              onChange={(e) => this.updateState('name', e.target.value)}
            />
            <div class="invalid-feedback">Please enter name</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Provider Platform
            </label>
            <select
              class="form-select"
              id="inputType"
              required="required"
              onChange={(e) => this.updateState('provider_platform', e.target.value)}
            >
              <option value="">Select Provider</option>
              {Object.keys(providers).map((el) => (
                <option selected={el == this.state.paymentMethod?.provider_platform} value={el}>
                  {el}
                </option>
              ))}
            </select>

            <div class="invalid-feedback">Please enter provider platform</div>
          </div>
          {
            <div class="col-md-12">
              <label class="form-label" htmlFor="inputEmail">
                Payment Platform
              </label>
              <select
                class="form-select"
                id="inputPay"
                onChange={(e) => this.updateState('pay_platform', e.target.value)}
              >
                <option value="">Select Pay Platform</option>
                {(providers[this.state.paymentMethod?.provider_platform] || []).map((el) => (
                  <option selected={el == this.state.paymentMethod?.pay_platform} value={el}>
                    {el}
                  </option>
                ))}
              </select>

              <div class="invalid-feedback">Please enter payment platform</div>
            </div>
          }
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Priority
            </label>
            <input
              class="form-control"
              id="inputPriority"
              type="number"
              value={this.state.paymentMethod?.priority}
              onChange={(e) => this.updateState('priority', e.target.value)}
            />
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Fee charge seller
            </label>
            <input
              class="form-control"
              id="inputEmail"
              type="text"
              required="required"
              value={this.state.paymentMethod?.fee_charge_seller}
              onChange={(e) => this.updateState('fee_charge_seller', e.target.value)}
            />
            <div class="invalid-feedback">Please enter fee charge seller</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Fee charge buyer
            </label>
            <input
              class="form-control"
              id="inputEmail"
              type="text"
              required="required"
              value={this.state.paymentMethod?.fee_charge_buyer}
              onChange={(e) => this.updateState('fee_charge_buyer', e.target.value)}
            />
            <div class="invalid-feedback">Please enter fee charge buyer</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputRank">
              Support Area
            </label>
            <input
              class="form-control"
              id="inputArea"
              type="text"
              value={this.state.paymentMethod?.allowed_area}
              onChange={(e) => this.updateState('allowed_area', e.target.value)}
            />
            <div class="invalid-feedback">Please enter support area</div>
          </div>
          <label htmlFor="organizerMultiple">Allowed Countries</label>
          <select
            class="form-select"
            id="country"
            data-choices="data-choices"
            multiple="multiple"
            name="country[]"
            onChange={this.onSelectDropdown}
            data-options='{"removeItemButton":true,"placeholder":true,"searchEnabled":true}'
          >
            <option value="">Select country...</option>
            {countries.map((country) => (
              <option value={country.value} selected={(this.state.selectedCountries || []).indexOf(country.value) >= 0}>
                {country.label}
              </option>
            ))}
          </select>
          <div class="mb-6">
            <h4 class="mb-3">Payment Logo</h4>
            <div class="mb-2">
              <img src={this.state.image_url} style={{ height: '50px' }} />
            </div>
            <div
              id="dropzone"
              class="dropzone dropzone-multiple p-0"
              data-options='{"url":"valid/url","maxFiles":1,"dictDefaultMessage":"Choose or Drop a file here"}'
            >
              <div class="fallback">
                <input type="file" name="file" />
              </div>
              <div class="dz-message text-center" data-dz-message="data-dz-message">
                <div class="dz-message-text">
                  <img class="me-2" src="/assets/img/icons/cloud-upload.svg" width="25" alt="" />
                  Drop your file here
                </div>
              </div>
              <div class="dz-preview dz-preview-multiple m-0 d-flex flex-column">
                <div class="d-flex pb-3 border-bottom border-translucent media px-2">
                  <div class="border p-2 rounded-2 me-2">
                    <img
                      class="rounded-2 dz-image"
                      src="/assets/img/icons/file.png"
                      alt="..."
                      data-dz-thumbnail="data-dz-thumbnail"
                    />
                  </div>
                  <div class="flex-1 d-flex flex-between-center">
                    <div>
                      <h6 data-dz-name="data-dz-name"></h6>
                      <div class="d-flex align-items-center">
                        <p class="mb-0 fs-9 text-body-quaternary lh-1" data-dz-size="data-dz-size"></p>
                        <div class="dz-progress">
                          <span class="dz-upload" data-dz-uploadprogress=""></span>
                        </div>
                      </div>
                      <span class="fs-10 text-danger" data-dz-errormessage="data-dz-errormessage"></span>
                    </div>
                    <div class="dropdown">
                      <button
                        class="btn btn-link text-body-quaternary btn-sm dropdown-toggle btn-reveal dropdown-caret-none"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="fas fa-ellipsis-h"></span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end border border-translucent py-2">
                        <a class="dropdown-item" href="#!" data-dz-remove="data-dz-remove">
                          Remove File
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-6">
            <h4 class="mb-3">Description</h4>
            <textarea
              class="tinymce"
              name="content"
              id="description"
              data-tinymce='{"height":"15rem","placeholder":"Write a description here..."}'
            ></textarea>
          </div>
          <div class="col-12">
            <button class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormPaymentMethods.propTypes = {
  auth: PropTypes.object.isRequired,
  paymentMethodDetail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createPaymentMethod: PropTypes.func.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired,
  getPaymentMethodDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  paymentMethodDetail: state.paymentMethods.paymentMethod,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createPaymentMethod,
  updatePaymentMethod,
  getPaymentMethodDetail,
  getPaymentMethodLists,
})(withRouter(FormPaymentMethods));
