import _ from 'lodash';

import { GET_SUBSCRIBER_LIST } from '../actions/types';

const initialState = {
  subscribers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIBER_LIST:
      return {
        ...state,
        subscribers: action.payload,
      };
    default:
      return state;
  }
}
