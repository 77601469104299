import _ from 'lodash';

import {
  GET_TRANSACTION_LIST,
  UPDATE_TRANSACTION,
  GET_TRANSACTION_DETAIL,
  UPDATE_PAYOUT,
  GET_PAYOUT_LIST,
} from '../actions/types';

const initialState = {
  transactions: {},
  payouts: {},
  transaction: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTION_LIST:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_TRANSACTION_DETAIL:
      return {
        ...state,
        transaction: action.payload,
      };
    case UPDATE_PAYOUT:
    case UPDATE_TRANSACTION:
      return {
        ...state,
      };
    case GET_PAYOUT_LIST:
      return {
        ...state,
        payouts: action.payload,
      };
    default:
      return state;
  }
}
