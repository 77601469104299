import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { formValidation, validateNumber } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';

import {
  createExpense,
  updateExpense,
  getExpenseDetail,
  getExpenseCategoryLists,
  getExpenseLists,
} from '../../../actions/expense';
import { getUserLists } from '../../../actions/user';

import { withRouter } from '../../../withRouter';

const requiredFields = ['name', 'amount', 'category_id'];

class FormExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Expense' : 'Create Expense',
      expense: {},
      buyers: [],
      buyer_id: null,
      expenseCategories: [],
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    if (name === 'amount' && value) {
      value = parseFloat(value);
    }
    this.setState({
      expense: {
        ...this.state.expense,
        [name]: value,
      },
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.expense, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      if (validateNumber(this.state.expense.amount)) {
        createNotification('error', 'Please enter number to amount');
      }
      if (this.props.router.params.id) {
        await this.props.updateExpense(this.props.router.params.id, this.state.expense);
      } else {
        await this.props.createExpense(this.state.expense);
      }
      this.props.getExpenseLists();
    }
  }

  makeBuyerAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getUserLists({
      field_name: 'status,keyword',
      field_value: `1,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };
  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onBuyerInput = this.debounce(this.makeBuyerAPICall, 500);

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getExpenseDetail(this.props.router.params.id);
    }
    this.props.getUserLists({
      field_name: 'status',
      field_value: 1,
      page: 1,
      page_size: 20,
    });
    this.props.getExpenseCategoryLists();
  }

  componentDidUpdate() {
    const that = this;
    const buyerElement = document.querySelector('#buyer');
    if (buyerElement && !window.buyerChoices) {
      window.buyerChoices = new window.Choices(buyerElement, {
        silent: true,
        searchEnabled: true,
        searchChoices: false,
        placeholder: true,
        placeholderValue: 'Search User',
        searchPlaceholderValue: 'Search User...',
        choices: [],
      });
      window.buyerChoices.clearChoices();
      buyerElement.addEventListener(
        'search',
        function (event) {
          that.onBuyerInput(event.detail.value);
        },
        false,
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.expenseDetail !== this.props.expenseDetail) {
      this.setState({ expense: nextProps.expenseDetail.data });
    }
    if (nextProps.expenseCategories !== this.props.expenseCategories) {
      this.setState({ expenseCategories: nextProps.expenseCategories.data });
    }

    if (nextProps.users !== this.props.users) {
      this.setState({
        ...this.state,
        buyers: nextProps.users.users.users,
      });
      if (window.buyerChoices) {
        window.buyerChoices.setChoices(
          (nextProps.users.users.users || []).map((el) => {
            return { value: el.id, label: el.name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <form className="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          {/* {!this.props.router.params.id && (
            <>
              <div className="col-md-12">
                <label className="form-label" htmlFor="inputFullName">
                  User
                </label>
                <select
                  id="user"
                  className="form-select mb-3"
                  onChange={(e) => this.setState({ buyer_id: e.target.value })}
                ></select>
              </div>
            </>
          )} */}
          <div className="col-md-12">
            <label className="form-label" htmlFor="inputName">
              Name
            </label>
            <input
              className="form-control"
              id="inputName"
              type="text"
              required="required"
              value={this.state.expense?.name}
              onChange={(e) => this.updateState('name', e.target.value)}
            />
            <div className="invalid-feedback">Please enter name</div>
          </div>
          <div className="col-md-12">
            <label className="form-label" htmlFor="inputAmount">
              Amount
            </label>
            <input
              className="form-control"
              id="inputAmount"
              type="text"
              required="required"
              value={this.state.expense?.amount}
              onChange={(e) => this.updateState('amount', e.target.value)}
            />
            <div className="invalid-feedback">Please enter amount</div>
          </div>
          <div className="col-md-12">
            <label className="form-label" htmlFor="inputType">
              Expense Category
            </label>
            <select
              className="form-select"
              id="inputType"
              required="required"
              onChange={(e) => this.updateState('category_id', e.target.value)}
            >
              <option value="">Select Category</option>
              {this.state.expenseCategories.map((el) => (
                <option selected={el._id == this.state.expense?.category_id} value={el._id}>
                  {el.name}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Please select Category </div>
          </div>

          <div className="col-md-12">
            <label className="form-label" htmlFor="inputDate">
              Requested Date
            </label>
            <div>
              <DatePicker
                className="form-control"
                placeholderText="Requested Date"
                selected={
                  this.state.expense?.requested_date ? new Date(this.state.expense?.requested_date * 1000) : new Date()
                }
                onChange={(dateStr) => {
                  this.updateState('requested_date', new Date(dateStr).getTime() / 1000);
                }}
              />
            </div>
          </div>

          <div className="col-12">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormExpenses.propTypes = {
  auth: PropTypes.object.isRequired,
  expenseDetail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createExpense: PropTypes.func.isRequired,
  updateExpense: PropTypes.func.isRequired,
  getExpenseDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  expenseDetail: state.expenses.expense,
  expenseCategories: state.expenses.expense_categories,
  errors: state.errors,
  users: state.users,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createExpense,
  updateExpense,
  getExpenseDetail,
  getUserLists,
  getExpenseCategoryLists,
  getExpenseLists,
})(withRouter(FormExpenses));
