import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import {
  DELETE_PRODUCT_TYPE,
  GET_ERRORS,
  GET_PRODUCT_TYPE_LIST,
  CREATE_PRODUCT_TYPE,
  UPDATE_PRODUCT_TYPE,
  GET_PRODUCT_TYPE_DETAIL,
} from './types';

// Get List of Users
export const getProductTypeLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/product/v1/auth/admin/product-types?${params}`)
      .then((res) => {
        dispatch({
          type: GET_PRODUCT_TYPE_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteProductType = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/product/v1/auth/admin/product-types/${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_PRODUCT_TYPE,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createProductType = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post('/product/v1/auth/admin/product-types', values)
      .then((res) => {
        dispatch({
          type: CREATE_PRODUCT_TYPE,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateProductType = (id, data) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/product/v1/auth/admin/product-types/${id}`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_PRODUCT_TYPE,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getProductType = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/product/v1/auth/admin/product-types/${id}`)
      .then((res) => {
        dispatch({
          type: GET_PRODUCT_TYPE_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
