import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';

import { createReview, updateReview, getReviewDetail } from '../../../actions/review';
import { getSellerLists } from '../../../actions/seller';
import { getUserLists } from '../../../actions/user';

import { withRouter } from '../../../withRouter';

const requiredFields = ['rate', 'comment'];

class FormReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Review' : 'Create Review',
      review: {},
      sellers: [],
      buyers: [],
      seller_id: null,
      buyer_id: null,
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    this.setState({
      review: {
        ...this.state.review,
        [name]: value,
      },
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.review, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      if (this.props.router.params.id) {
        this.props.updateReview(this.props.router.params.id, this.state.review);
      } else {
        this.props.createReview({
          ...this.state.review,
          buyer_id: this.state.buyer_id,
          seller_id: this.state.seller_id,
        });
      }
    }
  }

  makeSellerAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getSellerLists({
      field_name: 'kyc_status,keyword',
      field_value: `2,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };
  makeBuyerAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getUserLists({
      field_name: 'status,keyword',
      field_value: `1,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };
  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onSellerInput = this.debounce(this.makeSellerAPICall, 500);
  onBuyerInput = this.debounce(this.makeBuyerAPICall, 500);

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getReviewDetail(this.props.router.params.id);
    }
    this.props.getSellerLists({
      field_name: 'kyc_status',
      field_value: 2,
      page: 1,
      page_size: 20,
    });
    this.props.getUserLists({
      field_name: 'status',
      field_value: 1,
      page: 1,
      page_size: 20,
    });
  }

  componentDidUpdate() {
    const that = this;
    const element = document.querySelector('#seller');
    const buyerElement = document.querySelector('#buyer');
    if (element && !window.choices) {
      window.choices = new window.Choices(element, {
        silent: true,
        searchEnabled: true,
        searchChoices: false,
        placeholder: true,
        placeholderValue: 'Search Seller',
        searchPlaceholderValue: 'Search Seller...',
        choices: [],
      });
      window.choices.clearChoices();
      element.addEventListener(
        'search',
        function (event) {
          that.onSellerInput(event.detail.value);
        },
        false,
      );
    }

    if (buyerElement && !window.buyerChoices) {
      window.buyerChoices = new window.Choices(buyerElement, {
        silent: true,
        searchEnabled: true,
        searchChoices: false,
        placeholder: true,
        placeholderValue: 'Search Buyer',
        searchPlaceholderValue: 'Search Buyer...',
        choices: [],
      });
      window.buyerChoices.clearChoices();
      buyerElement.addEventListener(
        'search',
        function (event) {
          that.onBuyerInput(event.detail.value);
        },
        false,
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reviewDetail !== this.props.reviewDetail) {
      this.setState({ review: nextProps.reviewDetail.data });
    }

    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        ...this.state,
        sellers: nextProps.sellers.sellers.sellers,
      });
      if (window.choices) {
        window.choices.setChoices(
          (nextProps.sellers.sellers.sellers || []).map((el) => {
            return { value: el.user_id, label: el.user_name };
          }),
          'value',
          'label',
          true,
        );
      }
    }

    if (nextProps.users !== this.props.users) {
      this.setState({
        ...this.state,
        buyers: nextProps.users.users.users,
      });
      if (window.buyerChoices) {
        window.buyerChoices.setChoices(
          (nextProps.users.users.users || []).map((el) => {
            return { value: el.id, label: el.name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <form class="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          {!this.props.router.params.id && (
            <>
              <div class="col-md-12">
                <label class="form-label" htmlFor="inputFullName">
                  Seller
                </label>
                <select
                  id="seller"
                  class="form-select mb-3"
                  onChange={(e) => this.setState({ seller_id: e.target.value })}
                ></select>
              </div>
              <div class="col-md-12">
                <label class="form-label" htmlFor="inputFullName">
                  Buyer
                </label>
                <select
                  id="buyer"
                  class="form-select mb-3"
                  onChange={(e) => this.setState({ buyer_id: e.target.value })}
                ></select>
              </div>
            </>
          )}
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputFullName">
              Rate
            </label>
            <select
              class="form-select"
              id="inputType"
              required="required"
              onChange={(e) => this.updateState('rate', e.target.value * 1)}
            >
              <option value="">Select Rate{Array(5).fill((i) => i + 1)}</option>
              {Array.from({ length: 5 }, (_, i) => i + 1).map((el) => (
                <option selected={el == this.state.review?.rate} value={el}>
                  {el}
                </option>
              ))}
            </select>
            <div class="invalid-feedback">Please rate </div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Comment
            </label>
            <input
              class="form-control"
              id="inputEmail"
              type="text"
              required="required"
              value={this.state.review?.comment}
              onChange={(e) => this.updateState('comment', e.target.value)}
            />
            <div class="invalid-feedback">Please enter comment</div>
          </div>
          <div class="col-12">
            <button class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormReviews.propTypes = {
  auth: PropTypes.object.isRequired,
  reviewDetail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createReview: PropTypes.func.isRequired,
  updateReview: PropTypes.func.isRequired,
  getReviewDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  reviewDetail: state.reviews.review,
  errors: state.errors,
  sellers: state.sellers,
  users: state.users,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createReview,
  updateReview,
  getReviewDetail,
  getSellerLists,
  getUserLists,
})(withRouter(FormReviews));
