import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPayoutMethodLists, deletePayoutMethod } from '../../../actions/payout';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { convertCurrency } from '../../../utils/helpers';

class PayoutMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      payoutMethodList: [],
      total_items: 0,
    };
  }

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getPayoutMethodLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.deletePayoutMethod(id);
      const payoutMethodList = this.state.payoutMethodList.filter((el) => el._id !== id);
      this.setState({
        ...this.state,
        payoutMethodList,
      });
    }
    return false;
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.payoutMethods !== this.props.payoutMethods) {
      this.setState({
        ...this.state,
        payoutMethodList: nextProps.payoutMethods.payoutMethods.data,
      });
    }
  }
  render() {
    return (
      <>
        <nav class="mb-2" aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li class="breadcrumb-item active">Payout methods</li>
          </ol>
        </nav>
        <h2 class="text-bold text-body-emphasis mb-5">Payout methods</h2>
        <div
          id="members"
          data-list='{"valueNames":["username","email","role","security","last_active","joined"],"page":10,"pagination":true}'
        >
          <div class="row align-items-center justify-content-between g-3 mb-4">
            <div class="col col-auto">
              <div class="search-box">
                <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    class="form-control search-input search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex align-items-center">
                <button class="btn btn-link text-body me-4 px-0">
                  <span class="fa-solid fa-file-export fs-9 me-2"></span>Export
                </button>
                <a href="payout-methods/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add new
                </a>
              </div>
            </div>
          </div>
          <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div class="table-responsive scrollbar ms-n1 ps-1">
              <table class="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th class="white-space-nowrap fs-9 align-middle ps-0">
                      <div class="form-check mb-0 fs-8">
                        <input
                          class="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      NAME
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="email"
                      style={{ width: '15%', minWidth: '150px' }}
                    >
                      Type
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="email"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      PLATFORM
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="mobile_number"
                      style={{ width: '20%', minWidth: '200px' }}
                    >
                      FEES
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '21%', minWidth: '200px' }}
                    >
                      ADDITIONAL FEES
                    </th>
                    <th class="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody class="list" id="members-table-body">
                  {this.state.payoutMethodList.map((method) => (
                    <tr class="hover-actions-trigger btn-reveal-trigger position-static">
                      <td class="fs-9 align-middle ps-0 py-3">
                        <div class="form-check mb-0 fs-8">
                          <input class="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                        </div>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <div class=" d-flex align-items-center">
                          <div class="avatar avatar-m">
                            <img
                              class="rounded-circle"
                              src={
                                method.image_url ||
                                'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                              }
                              alt=""
                            />
                          </div>
                          <h6 class="username mb-0 ms-3 fw-semibold">{method.name}</h6>
                        </div>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold">{method.type}</h6>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold">{method.payout_platform}</h6>
                      </td>
                      <td class="mobile_number align-middle white-space-nowrap">{method.fee}%</td>
                      <td class="mobile_number align-middle white-space-nowrap">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(method.additional_fee, true, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td class="joined align-middle white-space-nowrap text-body-tertiary text-end">
                        <div class="btn-reveal-trigger position-static">
                          <button
                            class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                            type="button"
                            data-bs-toggle="dropdown"
                            data-boundary="window"
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-bs-reference="parent"
                          >
                            <span class="fas fa-ellipsis-h fs-10"></span>
                          </button>
                          <div class="dropdown-menu dropdown-menu-end py-2">
                            <a class="dropdown-item" href={`payout-methods/edit/${method._id}`}>
                              Edit
                            </a>
                            <a
                              class="dropdown-item text-danger"
                              onClick={() => this.handleDelete(method._id)}
                              href="#!"
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

PayoutMethods.propTypes = {
  auth: PropTypes.object.isRequired,
  payoutMethods: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getPayoutMethodLists: PropTypes.func.isRequired,
  deletePayoutMethod: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  payoutMethods: state.payoutMethods,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getPayoutMethodLists,
  deletePayoutMethod,
})(PayoutMethods);
