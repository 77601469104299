import {
  GET_GAME_TYPE_LIST,
  GET_GAME_TYPE_DETAIL,
  UPDATE_GAME_TYPE,
  DELETE_GAME_TYPE,
  CREATE_GAME_TYPE,
} from '../actions/types';

const initialState = {
  gameTypes: {},
  gameType: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GAME_TYPE_LIST:
      return {
        ...state,
        gameTypes: action.payload,
      };
    case GET_GAME_TYPE_DETAIL:
      return {
        ...state,
        gameType: action.payload,
      };
    case UPDATE_GAME_TYPE:
    case CREATE_GAME_TYPE:
    case DELETE_GAME_TYPE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
