import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import {
  GET_ERRORS,
  GET_SUPPORT_LIST,
  GET_SUPPORT_DETAIL,
  GET_SUPPORT_RESOLVE,
  UPDATE_SUPPORT,
  DELETE_SUPPORT,
  CREATE_RESOLVE,
  GET_SUPPORT_REPLIES,
} from './types';

// Get List of Supports
export const getSupportLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/ticket-management/v1/auth/admin/tickets?${params}`)
      .then((res) => {
        dispatch({
          type: GET_SUPPORT_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getSupportDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/ticket-management/v1/auth/admin/tickets/${id}/detail`)
      .then((res) => {
        dispatch({
          type: GET_SUPPORT_DETAIL,
          payload: res.data ? res.data?.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getSupportReplies = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/ticket-management/v1/auth/admin/tickets/${id}`)
      .then((res) => {
        dispatch({
          type: GET_SUPPORT_REPLIES,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteSupport = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/ticket-management/v1/auth/admin/tickets/${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_SUPPORT,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createSupport = (payload) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/ticket-management/v1/auth/admin/tickets`, payload)
      .then((res) => {
        dispatch({
          type: UPDATE_SUPPORT,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateSupport = (id, values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/ticket-management/v1/auth/admin/tickets/${id}`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_SUPPORT,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const replySupport = (data) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/ticket-management/v1/auth/admin/tickets`, data)
      .then((res) => {
        dispatch({
          type: UPDATE_SUPPORT,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getResolveDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/ticket-management/v1/auth/admin/tickets/${id}/resolve`)
      .then((res) => {
        dispatch({
          type: GET_SUPPORT_RESOLVE,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createResolve = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/ticket-management/v1/auth/admin/tickets/resolve`, values)
      .then((res) => {
        dispatch({
          type: CREATE_RESOLVE,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
