import _ from 'lodash';

import { GET_REVIEW_LIST, UPDATE_REVIEW, DELETE_REVIEW, GET_REVIEW_DETAIL } from '../actions/types';

const initialState = {
  reviews: {},
  review: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REVIEW_LIST:
      return {
        ...state,
        reviews: action.payload,
      };
    case GET_REVIEW_DETAIL:
      return {
        ...state,
        review: action.payload,
      };
    case UPDATE_REVIEW:
    case DELETE_REVIEW:
      return {
        ...state,
      };
    default:
      return state;
  }
}
