import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';

import { createUser, userUpdateData, getUserDetail } from '../../../actions/user';
import { USER_TYPE_ENUM, USER_STATUS_ENUM } from '../../../utils/constants';
import { withRouter } from '../../../withRouter';

const requiredFields = ['name', 'email', 'type', 'status'];

class FormUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit User' : 'Create User',
      user: {},
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.user, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      let updateUser = { ...this.state.user };
      if (this.state.user.pwd && this.state.user.pwd !== '') {
        updateUser['new_password'] = this.state.user.pwd;
      }
      if (this.props.router.params.id) {
        this.props.userUpdateData(this.props.router.params.id, updateUser);
      } else {
        this.props.createUser(updateUser);
      }
      createNotification('success', lang('success.update'));
    }
  }

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getUserDetail(this.props.router.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.userDetail !== this.props.userDetail) {
      this.setState({ user: nextProps.userDetail });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#!">Users</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <form class="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputFullName">
              Full Name
            </label>
            <input
              class="form-control"
              id="inputFullName"
              type="text"
              required="required"
              value={this.state.user?.name}
              onChange={(e) => this.updateState('name', e.target.value)}
            />
            <div class="invalid-feedback">Please enter Full name</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputEmail">
              Email
            </label>
            <input
              class="form-control"
              id="inputEmail"
              type="email"
              required="required"
              value={this.state.user?.email}
              onChange={(e) => this.updateState('email', e.target.value)}
            />
            <div class="invalid-feedback">Please enter Email</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputPassword">
              Password
            </label>
            <input
              class="form-control"
              id="inputPassword"
              type="password"
              autoComplete="off"
              onChange={(e) => this.updateState('pwd', e.target.value)}
            />
          </div>
          <div class="col-md-4">
            <label class="form-label" htmlFor="inputType">
              User Type
            </label>
            <select
              class="form-select"
              id="inputType"
              required="required"
              onChange={(e) => this.updateState('type', e.target.value * 1)}
            >
              <option value="">Select User Type</option>
              {Object.keys(USER_TYPE_ENUM).map((el) => (
                <option selected={USER_TYPE_ENUM[el] == this.state.user?.type} value={USER_TYPE_ENUM[el]}>
                  {el}
                </option>
              ))}
            </select>
            <div class="invalid-feedback">Please select User Type</div>
          </div>
          <div class="col-md-4">
            <label class="form-label" htmlFor="inputStatus">
              User Status
            </label>
            <select
              class="form-select"
              id="inputStatus"
              required="required"
              onChange={(e) => this.updateState('status', e.target.value * 1)}
            >
              <option value="">Select User Status</option>
              {USER_STATUS_ENUM.map((el, index) => (
                <option selected={index == this.state.user?.status * 1} value={index}>
                  {el}
                </option>
              ))}
            </select>
            <div class="invalid-feedback">Please select User Status</div>
          </div>
          <div class="col-12">
            <button class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormUsers.propTypes = {
  auth: PropTypes.object.isRequired,
  userDetail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  userUpdateData: PropTypes.func.isRequired,
  getUserDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userDetail: state.users.user,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createUser,
  userUpdateData,
  getUserDetail,
})(withRouter(FormUsers));
