import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getGameTypeLists, deleteGameType } from '../../../actions/gameType';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import advanceAjaxTableInit from '../../../utils/advanceAjaxTableInit';

class GameTypeTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      gameTypeList: [],
      total_items: 0,
    };
  }
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getGameTypeLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.deleteGameType(id);
      const gameTypeList = this.state.gameTypeList.filter((el) => el._id !== id);
      this.setState({
        ...this.state,
        gameTypeList,
      });
    }
    return false;
  };
  generateTable = (gameTypes) => {
    const options = {
      page: 12,
      pagination: {
        item: "<li><button class='page' type='button'></button></li>",
      },
      item: (method) => {
        return `
    <tr class="hover-actions-trigger btn-reveal-trigger position-static">
    <td class="fs-9 align-middle ps-0 py-3">
      <div class="form-check mb-0 fs-8">
        <input
          class="form-check-input"
          type="checkbox"
          data-bulk-select-row="[]"
        />
      </div>
    </td>
    <td class="customer align-middle white-space-nowrap">
      <a
        class="d-flex align-items-center text-body text-hover-1000"
        href="#!"
      >
        <div class="avatar avatar-m">
          <img
            class="rounded-circle"
            src=${method.image_url || 'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'}
            alt=""
          />
        </div>
        <h6 class="username mb-0 ms-3 fw-semibold">
          ${method.name}
        </h6>
      </a>
    </td>
    <td class="customer align-middle white-space-nowrap">
      <h6 class="username mb-0 fw-semibold">${method.slug}</h6>
    </td>
    <td class="mobile_number align-middle white-space-nowrap">
      ${method.priority}
    </td>
    <td class="joined align-middle white-space-nowrap text-body-tertiary text-end">
      <div class="btn-reveal-trigger position-static">
        <button
          class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
          type="button"
          data-bs-toggle="dropdown"
          data-boundary="window"
          aria-haspopup="true"
          aria-expanded="false"
          data-bs-reference="parent"
        >
          <span class="fas fa-ellipsis-h fs-10"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-end py-2">
          <a
            class="dropdown-item"
            href=${`types/edit/${method._id}`}
          >
            Edit
          </a>
          <a
            class="dropdown-item text-danger"
            href="#!"
          >
            Remove
          </a>
        </div>
      </div>
    </td>
  </tr>
    `;
      },
    };

    advanceAjaxTableInit(options, gameTypes);
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.gameTypes !== this.props.gameTypes) {
      this.setState({
        ...this.state,
        gameTypeList: nextProps.gameTypes.gameTypes.data,
      });
      this.generateTable(nextProps.gameTypes.gameTypes.data);
    }
  }
  render() {
    return (
      <>
        <nav class="mb-2" aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li class="breadcrumb-item active">Game Types</li>
          </ol>
        </nav>
        <h2 class="text-bold text-body-emphasis mb-5">Game Types</h2>
        <div
          id="advanceTable"
          data-list='{"valueNames":["name","slug","priority"],"page":12,"pagination":{"innerWindow":2,"left":1,"right":1}}'
        >
          <div class="row align-items-center justify-content-between g-3 mb-4">
            <div class="col col-auto">
              <div class="search-box">
                <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    class="form-control search-input search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex align-items-center">
                <button class="btn btn-link text-body me-4 px-0">
                  <span class="fa-solid fa-file-export fs-9 me-2"></span>Export
                </button>
                <a href="types/add" class="btn btn-primary">
                  <span class="fas fa-plus me-2"></span>Add new
                </a>
              </div>
            </div>
          </div>
          <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div class="table-responsive scrollbar ms-n1 ps-1">
              <table class="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th class="white-space-nowrap fs-9 align-middle ps-0">
                      <div class="form-check mb-0 fs-8">
                        <input
                          class="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '30%', minWidth: '200px' }}
                    >
                      NAME
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="email"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      SLUG
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="mobile_number"
                      style={{ width: '20%', minWidth: '200px' }}
                    >
                      PRIORITY
                    </th>
                    <th class="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody class="list" id="members-table-body"></tbody>
              </table>
            </div>
            <div class="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body" data-list-info="data-list-info"></p>
                <a class="fw-semibold" href="#!" data-list-view="*">
                  View all
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
                <a class="fw-semibold d-none" href="#!" data-list-view="less">
                  View Less
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
              </div>
              <div class="col-auto d-flex">
                <button class="page-link" data-list-pagination="prev">
                  <span class="fas fa-chevron-left"></span>
                </button>
                <ul class="mb-0 pagination"></ul>
                <button class="page-link pe-0" data-list-pagination="next">
                  <span class="fas fa-chevron-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

GameTypeTypes.propTypes = {
  auth: PropTypes.object.isRequired,
  gameTypes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getGameTypeLists: PropTypes.func.isRequired,
  deleteGameType: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  gameTypes: state.gameTypes,
});

export default connect(mapStatetoProps, {
  getGameTypeLists,
  deleteGameType,
})(GameTypeTypes);
