import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTopBuyersReports } from '../../../actions/report';
import { convertCurrency } from '../../../utils/helpers';

class TopBuyers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      general: {},
    };
  }
  componentDidMount() {
    this.props.getTopBuyersReports();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.report !== nextProps.report) {
      this.setState({
        report: nextProps.report.data || [],
      });
    }
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-2">Top Buyers</h4>
          <div className="table-responsive scrollbar">
            <table className="reports-details-chart-table table table-sm fs-9 mb-0">
              <thead>
                <tr>
                  <th
                    className="align-middle pe- text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '35%' }}
                  >
                    Buyer
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '35%' }}
                  >
                    Total Purchase
                  </th>
                </tr>
              </thead>
              <tbody className="list" id="report-data-body">
                {(this.state.report || []).length > 0 &&
                  this.state.report.map((el, index) => (
                    <tr key={index} className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="align-middle white-space-nowrap fw-semibold text-body-highlight py-2">
                        {el.buyer_name}
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(el.price || 0, false, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

TopBuyers.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  report: state.report.buyers,
  general: state.report.general,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, { getTopBuyersReports })(TopBuyers);
