// Auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const ADMIN_ACCESS_ENUM = ['admin', 'staff'];

// User
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_ALL_USER_LIST = 'GET_ALL_USER_LIST';
export const GET_MEMBER_USER_LIST = 'GET_MEMBER_USER_LIST';
export const UPDATE_USER = 'UPDATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_SUBSCRIBER_LIST = 'GET_SUBSCRIBER_LIST';

// Seller
export const GET_SELLER_LIST = 'GET_SELLER_LIST';
export const GET_SELLER_DETAIL = 'GET_SELLER_DETAIL';
export const UPDATE_SELLER = 'UPDATE_SELLER';
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

// Seller ranks
export const GET_RANK_LIST = 'GET_RANK_LIST';
export const UPDATE_RANK = 'UPDATE_RANK';
export const CREATE_RANK = 'CREATE_RANK';
export const DELETE_RANK = 'DELETE_RANK';
export const GET_RANK_DETAIL = 'GET_RANK_DETAIL';

// Review
export const GET_REVIEW_LIST = 'GET_REVIEW_LIST';
export const GET_REVIEW_DETAIL = 'GET_REVIEW_DETAIL';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const CREATE_REVIEW = 'UPDATE_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';

// EXPENSE
export const GET_EXPENSE_LIST = 'GET_EXPENSE_LIST';
export const GET_EXPENSE_DETAIL = 'GET_EXPENSE_DETAIL';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export const CREATE_EXPENSE = 'UPDATE_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const GET_EXPENSE_CATEGORY_LIST = 'GET_EXPENSE_CATEGORY_LIST';
export const GET_EXPENSE_CATEGORY_DETAIL = 'GET_EXPENSE_CATEGORY_DETAIL';
export const UPDATE_EXPENSE_CATEGORY = 'UPDATE_EXPENSE_CATEGORY';
export const CREATE_EXPENSE_CATEGORY = 'UPDATE_EXPENSE_CATEGORY';
export const DELETE_EXPENSE_CATEGORY = 'DELETE_EXPENSE_CATEGORY';

// Payouts
export const GET_PAYOUT_METHOD_LIST = 'GET_PAYOUT_METHOD_LIST';
export const UPDATE_PAYOUT_METHOD = 'UPDATE_PAYOUT_METHOD';
export const CREATE_PAYOUT_METHOD = 'CREATE_PAYOUT_METHOD';
export const DELETE_PAYOUT_METHOD = 'DELETE_PAYOUT_METHOD';
export const GET_PAYOUT_METHOD_DETAIL = 'GET_PAYOUT_METHOD_DETAIL';

// Payments
export const GET_PAYMENT_METHOD_LIST = 'GET_PAYMENT_METHOD_LIST';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const GET_PAYMENT_METHOD_DETAIL = 'GET_PAYMENT_METHOD_DETAIL';
export const GET_PAYMENT_SETTINGS = 'GET_PAYMENT_SETTINGS';
export const UPDATE_PAYMENT_SETTINGS = 'UPDATE_PAYMENT_SETTINGS';

//game types
export const GET_GAME_TYPE_LIST = 'GET_GAME_TYPE_LIST';
export const GET_GAME_TYPE_DETAIL = 'GET_GAME_TYPE_DETAIL';
export const UPDATE_GAME_TYPE = 'UPDATE_GAME_TYPE';
export const CREATE_GAME_TYPE = 'CREATE_GAME_TYPE';
export const DELETE_GAME_TYPE = 'DELETE_GAME_TYPE';

// games
export const GET_GAME_LIST = 'GET_GAME_LIST';
export const UPDATE_GAME = 'UPDATE_GAME';
export const CREATE_GAME = 'CREATE_GAME';
export const DELETE_GAME = 'DELETE_GAME';
export const GET_GAME_DETAIL = 'GET_GAME_DETAIL';
export const CREATE_GAME_VARIANT = 'CREATE_GAME_VARIANT';
export const UPDATE_GAME_VARIANT = 'UPDATE_GAME_VARIANT';
export const DELETE_GAME_VARIANT = 'DELETE_GAME_VARIANT';
export const CREATE_GAME_PRODUCT_TYPE = 'CREATE_GAME_PRODUCT_TYPE';
export const DELETE_GAME_PRODUCT_TYPE = 'DELETE_GAME_PRODUCT_TYPE';

// product types
export const GET_PRODUCT_TYPE_LIST = 'GET_PRODUCT_TYPE_LIST';
export const GET_PRODUCT_TYPE_DETAIL = 'GET_PRODUCT_TYPE_DETAIL';
export const UPDATE_PRODUCT_TYPE = 'UPDATE_PRODUCT_TYPE';
export const CREATE_PRODUCT_TYPE = 'CREATE_PRODUCT_TYPE';
export const DELETE_PRODUCT_TYPE = 'DELETE_PRODUCT_TYPE';

// products
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

// transaction
export const GET_TRANSACTION_LIST = 'GET_TRANSACTION_LIST';
export const GET_TRANSACTION_DETAIL = 'GET_TRANSACTION_DETAIL';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const GET_PAYOUT_LIST = 'GET_PAYOUT_LIST';
export const UPDATE_PAYOUT = 'UPDATE_PAYOUT';

// support
export const GET_SUPPORT_LIST = 'GET_SUPPORT_LIST';
export const GET_SUPPORT_DETAIL = 'GET_SUPPORT_DETAIL';
export const GET_SUPPORT_RESOLVE = 'GET_SUPPORT_RESOLVE';
export const UPDATE_SUPPORT = 'UPDATE_SUPPORT';
export const DELETE_SUPPORT = 'DELETE_SUPPORT';
export const CREATE_RESOLVE = 'CREATE_RESOLVE';
export const GET_SUPPORT_REPLIES = 'GET_SUPPORT_REPLIES';
export const GET_ROOM_LIST = 'GET_ROOM_LIST';
export const ARCHIVE_ROOM = 'ARCHIVE_ROOM';

// Exceptions
export const GET_ERRORS = 'GET_ERRORS';

// Reports
export const GET_GENERAL_REPORTS = 'GET_GENERAL_REPORTS';
export const GET_SELLER_REPORTS = 'GET_SELLER_REPORTS';
export const GET_BUYER_REPORTS = 'GET_BUYER_REPORTS';
export const GET_ANNUAL_GENERAL_REPORTS = 'GET_ANNUAL_GENERAL_REPORTS';
export const GET_SELLER_WALLETS = 'GET_SELLER_WALLETS';
export const UPDATE_SELLER_WALLETS = 'UPDATE_SELLER_WALLETS';
