import _ from 'lodash';

import {
  GET_PAYMENT_METHOD_LIST,
  UPDATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_DETAIL,
  GET_PAYMENT_SETTINGS,
  UPDATE_PAYMENT_SETTINGS,
} from '../actions/types';

const initialState = {
  paymentMethods: {},
  paymentMethod: {},
  settings: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_SETTINGS:
    case UPDATE_PAYMENT_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case GET_PAYMENT_METHOD_LIST:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case GET_PAYMENT_METHOD_DETAIL:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case CREATE_PAYMENT_METHOD:
    case UPDATE_PAYMENT_METHOD:
    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
      };
    default:
      return state;
  }
}
