import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import subscriberReducer from './subscriberReducer';
import membershipReducer from './membershipReducer';
import payoutReducer from './payoutReducer';
import paymentReducer from './paymentReducer';

import gameTypeReducer from './gameTypeReducer';
import productTypeReducer from './productTypeReducer';
import productReducer from './productReducer';
import gameReducer from './gameReducer';

import sellerReducer from './sellerReducer';
import reviewReducer from './reviewReducer';
import transactionReducer from './transactionReducer';
import ticketReducer from './ticketReducer';
import chatReducer from './chatReducer';
import reportReducer from './reportReducer';
import expenseReducer from './expenseReducer';

import errorReducer from './errorReducer';

import { loadingBarReducer } from 'react-redux-loading-bar';

export default combineReducers({
  auth: authReducer,
  users: userReducer,
  memberships: membershipReducer,
  sellers: sellerReducer,
  reviews: reviewReducer,
  transactions: transactionReducer,
  payoutMethods: payoutReducer,
  paymentMethods: paymentReducer,
  products: productReducer,
  tickets: ticketReducer,
  games: gameReducer,
  productTypes: productTypeReducer,
  gameTypes: gameTypeReducer,
  errors: errorReducer,
  loadingBar: loadingBarReducer,
  chat: chatReducer,
  report: reportReducer,
  expenses: expenseReducer,
  newsletter: subscriberReducer,
});
