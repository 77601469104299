import _ from 'lodash';

import {
  GET_USER_LIST,
  GET_ALL_USER_LIST,
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  GET_USER_DETAIL,
} from '../actions/types';

const initialState = {
  users: {},
  user: {},
  allUsers: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USER_LIST:
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_USER_LIST:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USER_DETAIL:
      return {
        ...state,
        user: action.payload,
      };
    case CREATE_USER:
    case UPDATE_USER:
    case DELETE_USER:
      return {
        ...state,
      };
    default:
      return state;
  }
}
