/* eslint-disable */
const advanceAjaxTableInit = (options, orders = null) => {
  const togglePaginationButtonDisable = (button, disabled) => {
    button.disabled = disabled;
    button.classList[disabled ? 'add' : 'remove']('disabled');
  };
  // Selectors
  const table = document.getElementById('advanceTable');

  if (table) {
    const paginationButtonNext = table.querySelector('[data-list-pagination="next"]');
    const paginationButtonPrev = table.querySelector('[data-list-pagination="prev"]');
    const viewAll = table.querySelector('[data-list-view="*"]');
    const viewLess = table.querySelector('[data-list-view="less"]');
    const listInfo = table.querySelector('[data-list-info]');
    const listFilter = document.querySelector('[data-list-filter]');

    const orderList = new window.List(table, options);
    if (orders !== null) {
      orderList.clear();
      orderList.add(orders);
    }

    // Fallback
    orderList.on('updated', (item) => {
      const fallback = table.querySelector('.fallback') || document.getElementById(options.fallback);

      if (fallback) {
        if (item.matchingItems.length === 0) {
          fallback.classList.remove('d-none');
        } else {
          fallback.classList.add('d-none');
        }
      }
    });

    const totalItem = orderList.items.length;
    const itemsPerPage = orderList.page;
    const btnDropdownClose = orderList.listContainer.querySelector('.btn-close');
    let pageQuantity = Math.ceil(totalItem / itemsPerPage);
    let numberOfcurrentItems = orderList.visibleItems.length;
    let pageCount = 1;

    btnDropdownClose && btnDropdownClose.addEventListener('search.close', () => orderList.fuzzySearch(''));

    const updateListControls = () => {
      listInfo && (listInfo.innerHTML = `${orderList.i} to ${numberOfcurrentItems} of ${totalItem}`);
      paginationButtonPrev && togglePaginationButtonDisable(paginationButtonPrev, pageCount === 1);
      paginationButtonNext && togglePaginationButtonDisable(paginationButtonNext, pageCount === pageQuantity);

      if (pageCount > 1 && pageCount < pageQuantity) {
        togglePaginationButtonDisable(paginationButtonNext, false);
        togglePaginationButtonDisable(paginationButtonPrev, false);
      }
    };
    updateListControls();

    if (paginationButtonNext) {
      paginationButtonNext.addEventListener('click', (e) => {
        e.preventDefault();
        pageCount += 1;

        const nextInitialIndex = orderList.i + itemsPerPage;
        nextInitialIndex <= orderList.size() && orderList.show(nextInitialIndex, itemsPerPage);
        numberOfcurrentItems += orderList.visibleItems.length;
        updateListControls();
      });
    }

    if (paginationButtonPrev) {
      paginationButtonPrev.addEventListener('click', (e) => {
        e.preventDefault();
        pageCount -= 1;

        numberOfcurrentItems -= orderList.visibleItems.length;
        const prevItem = orderList.i - itemsPerPage;
        prevItem > 0 && orderList.show(prevItem, itemsPerPage);
        updateListControls();
      });
    }

    const toggleViewBtn = () => {
      viewLess.classList.toggle('d-none');
      viewAll.classList.toggle('d-none');
    };

    if (viewAll) {
      viewAll.addEventListener('click', () => {
        orderList.show(1, totalItem);
        pageQuantity = 1;
        pageCount = 1;
        numberOfcurrentItems = totalItem;
        updateListControls();
        toggleViewBtn();
      });
    }
    if (viewLess) {
      viewLess.addEventListener('click', () => {
        orderList.show(1, itemsPerPage);
        pageQuantity = Math.ceil(totalItem / itemsPerPage);
        pageCount = 1;
        numberOfcurrentItems = orderList.visibleItems.length;
        updateListControls();
        toggleViewBtn();
      });
    }
    if (options.pagination) {
      table.querySelector('.pagination').addEventListener('click', (e) => {
        if (e.target.classList[0] === 'page') {
          pageCount = Number(e.target.innerText);
          updateListControls();
        }
      });
    }
    if (options.filter) {
      const { key } = options.filter;
      listFilter.addEventListener('change', (e) => {
        orderList.filter((item) => {
          if (e.target.value === '') {
            return true;
          }
          return item.values()[key].toLowerCase().includes(e.target.value.toLowerCase());
        });
      });
    }
    return orderList;
  }
};

export default advanceAjaxTableInit;
