import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import {
  DELETE_PAYMENT_METHOD,
  GET_ERRORS,
  GET_PAYMENT_METHOD_LIST,
  CREATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_DETAIL,
  GET_PAYMENT_SETTINGS,
  UPDATE_PAYMENT_SETTINGS,
} from './types';

// Get List of Users
export const getPaymentMethodLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/payment-methods?${params}`)
      .then((res) => {
        dispatch({
          type: GET_PAYMENT_METHOD_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deletePaymentMethod = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/payment/v1/auth/admin/payment-methods/${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_PAYMENT_METHOD,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createPaymentMethod = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/payment/v1/auth/admin/payment-methods`, values)
      .then((res) => {
        dispatch({
          type: CREATE_PAYMENT_METHOD,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updatePaymentMethod = (id, values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/payment/v1/auth/admin/payment-methods/${id}`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_PAYMENT_METHOD,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getPaymentMethodDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/payment/v1/auth/admin/payment-methods/${id}`, {})
      .then((res) => {
        dispatch({
          type: GET_PAYMENT_METHOD_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getSettings = () => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/payment/v1/auth/admin/payment-settings`, {})
      .then((res) => {
        dispatch({
          type: GET_PAYMENT_SETTINGS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateSettings = (params) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/payment/v1/auth/admin/payment-settings`, params)
      .then((res) => {
        dispatch({
          type: UPDATE_PAYMENT_SETTINGS,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
