import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';

import {
  createExpenseCategory,
  updateExpenseCategory,
  getExpenseCategoryDetail,
  getExpenseCategoryLists,
} from '../../../actions/expense';

import { withRouter } from '../../../withRouter';

const requiredFields = ['name'];

class FormExpenseCategoryCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Expense Category' : 'Create Expense Category',
      expense: {},
      expenseCategories: [],
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    this.setState({
      expense: {
        ...this.state.expense,
        [name]: value,
      },
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.expense, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      if (this.props.router.params.id) {
        await this.props.updateExpenseCategory(this.props.router.params.id, this.state.expense);
      } else {
        await this.props.createExpenseCategory(this.state.expense);
      }
      await this.props.getExpenseCategoryLists({
        page: 1,
        page_size: 10,
      });
      this.props.router.navigate(-1);
    }
  }

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getExpenseCategoryDetail(this.props.router.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.expenseDetail !== this.props.expenseDetail) {
      this.setState({ expense: nextProps.expenseDetail.data });
    }
    if (nextProps.expenseCategories !== this.props.expenseCategories) {
      this.setState({ expenseCategories: nextProps.expenseCategories.data });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <form className="row g-3 needs-validation" onSubmit={this.handleSave} novalidate="">
          <div className="col-md-12">
            <label className="form-label" htmlFor="inputName">
              Name
            </label>
            <input
              className="form-control"
              id="inputName"
              type="text"
              required="required"
              value={this.state.expense?.name}
              onChange={(e) => this.updateState('name', e.target.value)}
            />
            <div className="invalid-feedback">Please enter category name</div>
          </div>
          <div className="col-12">
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormExpenseCategoryCategories.propTypes = {
  auth: PropTypes.object.isRequired,
  expenseDetail: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createExpenseCategory: PropTypes.func.isRequired,
  updateExpenseCategory: PropTypes.func.isRequired,
  getExpenseCategoryDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  expenseDetail: state.expenses.expense_category,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  createExpenseCategory,
  updateExpenseCategory,
  getExpenseCategoryDetail,
  getExpenseCategoryLists,
})(withRouter(FormExpenseCategoryCategories));
