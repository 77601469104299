import _ from 'lodash';
import lang from '../langs';

export const loginValidate = (userData) => {
  return new Promise((resolve, reject) => {
    let output = {};

    if (_.isEmpty(userData.email)) {
      output.username = lang('required.email');
    }

    if (_.isEmpty(userData.password)) {
      output.password = lang('required.password');
    }

    if (_.isEmpty(output)) {
      resolve(userData);
    } else {
      reject(output);
    }
  });
};

export const formValidation = (data, requiredFields) => {
  return new Promise((resolve, reject) => {
    let output = {};

    requiredFields.forEach((field) => {
      let item = _.isString(data[field]) ? data[field] : JSON.stringify(data[field]);
      if (_.isEmpty(item)) {
        output[field] = field + lang('required_fields');
      }
    });
    if (_.isEmpty(output)) {
      resolve(data);
    } else {
      reject(output);
    }
  });
};

export const validateNumber = (value) => {
  return isNaN(value);
};
