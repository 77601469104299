import {
  GET_PRODUCT_TYPE_LIST,
  GET_PRODUCT_TYPE_DETAIL,
  UPDATE_PRODUCT_TYPE,
  DELETE_PRODUCT_TYPE,
  CREATE_PRODUCT_TYPE,
} from '../actions/types';

const initialState = {
  productTypes: {},
  productType: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_TYPE_LIST:
      return {
        ...state,
        productTypes: action.payload,
      };
    case GET_PRODUCT_TYPE_DETAIL:
      return {
        ...state,
        productType: action.payload,
      };
    case UPDATE_PRODUCT_TYPE:
    case CREATE_PRODUCT_TYPE:
    case DELETE_PRODUCT_TYPE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
