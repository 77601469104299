import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import { GET_ERRORS, GET_RANK_LIST, UPDATE_RANK, CREATE_RANK, DELETE_RANK, GET_RANK_DETAIL } from './types';

// Get List of Users
export const getMembershipLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/membership/v1/auth/admin/memberships?${params}`)
      .then((res) => {
        dispatch({
          type: GET_RANK_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const membershipDataDelete = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/membership/v1/auth/admin/memberships?membership_id=${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_RANK,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createMembership = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/membership/v1/auth/admin/memberships`, values)
      .then((res) => {
        dispatch({
          type: CREATE_RANK,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateMembership = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/membership/v1/auth/admin/memberships`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_RANK,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getMembershipDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/membership/v1/auth/admin/memberships/${id}`)
      .then((res) => {
        dispatch({
          type: GET_RANK_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
